import axios from 'axios';
import { getAccessToken } from '../utils/session';

export default function API(method, endPoint, data) {
  return axios({
    method: method,
    url: endPoint,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    data: data,
    params: data && data.params ? data.params : null
  })
}

export { API };
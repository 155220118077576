export function transactionsListDTO(data) {
  let transactions = [];

  if (data?.length) {
    transactions = data.map((transaction) => ({
      id: transaction?.id || null,
      createdAt: transaction?.createdAt || null,
      transactionDate: transaction?.transactionDate || null,
      amount: transaction?.amount || null,
      status: transaction?.status,
      tableNumber: transaction?.tableNumber || null,
      restaurantId: transaction?.restaurantId || null,
      phone: transaction?.phone || null,
      description: transaction?.description || null,
      orderId: transaction?.orderId || null,
    }))
  }

  return transactions;
}
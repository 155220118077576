import { useEffect, useState } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import { chartOptions, parseOptions } from "../../../../variables/charts.js";
import Cards from "../../../../components/Cards/index.jsx";
import { dispatch } from "../../../../utils/store/index.js";
import { Actions } from "../../../../redux/actions.js";
import API from "../../../../services/api.js";
import { API_REQUEST, calenderViewMode, ordersChartTooltips, ordersChartscales, } from "../../../../constants/General/index.js";
import { RESTAURANT_DASHBOARD_API } from "../../../../constants/Configs/index.js";
import { handleError } from "../../../../utils/toast/index.js";
import { faPieChart, faSterlingSign, faUsers } from "@fortawesome/free-solid-svg-icons";
import { dashboardDataDTO } from "../../utils/dtos/dashboard.js";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRangeSelector from "../../components/DateRangeSelector/index.jsx";
import { isAllowFeature } from "../../../../services/middleware.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, } from '@fortawesome/free-brands-svg-icons';

const Dashboard = () => {
  // Store
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // State
  const [dashboardData, setDashboardData] = useState(null)
  const [dateRangeSelector, setDateRangeSelector] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf('month').toISOString())
  const [endDate, setEndDate] = useState(moment().endOf('month').toISOString())

  useEffect(() => getDashboardData(), [startDate, endDate])

  const getDashboardData = () => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    API(API_REQUEST.get, `${RESTAURANT_DASHBOARD_API}?startDate=${startDate}&endDate=${endDate}`)
      .then(res => {
        setDashboardData(dashboardDataDTO(res?.data?.data))
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  }

  return (
    <div>
      <div className="card-list-wrapper mb-0">
        <div className="flex-end mb-4 ">
          {dashboardData?.facebookAccountDetail?.status && dashboardData?.facebookAccountDetail?.todaysMessagingLimit !== undefined && dashboardData?.facebookAccountDetail?.remainingMessageLimit !== undefined && <Button disabled color={dashboardData?.facebookAccountDetail?.status === 'CONNECTED' ? "success" : "primary"} outline onClick={() => setDateRangeSelector(true)}>
            <div className="facebook-widget">
              <div className="center-div">
                <FontAwesomeIcon icon={faFacebook} className="mr-1" /><span>{dashboardData?.facebookAccountDetail?.status || ''}</span>
              </div>
              <span className="text-right">{((dashboardData?.facebookAccountDetail?.todaysMessagingLimit || 0) - (dashboardData?.facebookAccountDetail?.remainingMessageLimit || 0))}/{dashboardData?.facebookAccountDetail?.todaysMessagingLimit || 0}</span>
            </div>
          </Button>}
          <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
            {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
          </Button>
        </div>
        <div>
          {/* Card stats */}
          <Row>
            {/* {isAllowFeature('tables') && <Cards label="Reserve Tables" value={dashboardData?.reservedTableDetails?.count} parcentage={dashboardData?.reservedTableDetails?.rate} lastData={dashboardData?.reservedTableDetails?.lastMonthCount} tag="Since last month" icon={faBarChart} color="purple" xl={3} />} */}
            {isAllowFeature('customers') && <Cards label="New Customers" value={dashboardData?.userDetail?.count} icon={faPieChart} color="green" xl={4} />}
            {isAllowFeature('orders') && <Cards label="Total Receipts" value={dashboardData?.orderDetail?.count} icon={faUsers} color="orange" xl={4} />}
            {/* {isAllowFeature('categories') && <Cards label="Menu Items" value={dashboardData?.menuItemDetail?.count} parcentage={dashboardData?.menuItemDetail?.rate} lastData={dashboardData?.menuItemDetail?.lastMonthCount} tag="Since last month" icon={faPercent} color="blue" xl={3} />} */}
            {isAllowFeature('orders') && <Cards label="Revenue" value={(settings?.superAdminSetting?.currencySign || '') + (dashboardData?.revenueDetails?.count || 0).toFixed(2)} icon={faSterlingSign} color="blue" xl={4} />}
          </Row>
        </div>
      </div>

      {/* Page content */}
      <div className="card-list-wrapper">
        <Row>
          <Col className="mb-4 mb-xl-0" xl="6">
            <Card className="">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Revenue</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {isAllowFeature('orders') ? <Line
                    data={{
                      labels: dashboardData?.chart?.revenue.map(data => data?.[dashboardData?.chart?.type === 'monthly' ? 'month' : dashboardData?.chart?.type === 'yearly' ? 'year' : 'date']),
                      datasets: [
                        {
                          label: "Performance",
                          data: dashboardData?.chart?.revenue.map(data => data.total)
                        }
                      ]
                    }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            gridLines: {
                              color: "#212529",
                              zeroLineColor: "#212529"
                            },
                            ticks: {
                              callback: function (value) {
                                if (!(value % 10)) {
                                  return settings?.superAdminSetting?.currencySign + value + "k";
                                }
                              }
                            }
                          }
                        ]
                      },
                      tooltips: {
                        callbacks: {
                          label: function (item, data) {
                            var label = data.datasets[item.datasetIndex].label || "";
                            var yLabel = item.yLabel;
                            var content = "";

                            if (data.datasets.length > 1) {
                              content += label;
                            }

                            content += settings?.superAdminSetting?.currencySign + yLabel + "k";
                            return content;
                          }
                        }
                      }
                    }}
                  /> : <div className="full-width full-height center-div">Please upgrade your subscription to access this feature.</div>}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card className="">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Total Receipts</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {isAllowFeature('orders') ? <Bar
                    data={{
                      labels: dashboardData?.chart?.receipts.map(data => data?.[dashboardData?.chart?.type === 'monthly' ? 'month' : dashboardData?.chart?.type === 'yearly' ? 'year' : 'date']),
                      datasets: [
                        {
                          label: "Sales",
                          data: dashboardData?.chart?.receipts.map(data => data.total),
                          maxBarThickness: 10
                        }
                      ]
                    }}
                    options={{
                      scales: ordersChartscales,
                      tooltips: ordersChartTooltips
                    }}
                  /> : <div className="full-width full-height center-div">Please upgrade your subscription to access this feature.</div>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        viewMode={calenderViewMode.day}
        setStartDate={(date) => setStartDate(date)}
        setEndDate={(date) => setEndDate(date)}
      />}
    </div >
  );
};

export default Dashboard;

import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import Select from 'react-select';
import { Button, Card, CardBody, Form, Label, Modal } from "reactstrap";
import { trimObjectValues } from '../../../../services/middleware';
import { reservationValidationSchema } from '../../../../constants/Schemas';
import { getOpenTables } from '../../utils/middleware/reservations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectDropdownStyle } from '../../../../constants/General';
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';

export default function AcceptReservation(props) {
  // Props
  const { open, tableId, reservation, handleCloseModal, handleSuccess } = props

  // Store 
  const tables = useSelector(state => state.restaurantAdminReservations.tables)

  const formik = useFormik({
    initialValues: {
      tableId: tableId || ''
    },
    validationSchema: reservationValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      handleSuccess(values?.tableId?.value)
    }
  });

  useEffect(() => getOpenTables(reservation?.startTime, reservation?.endTime), [reservation])

  useEffect(() => {
    if (tableId) {
      let updatedTables = tables
      updatedTables.push(tableId)
      dispatch(Actions.RestaurantAdminReservations.SetTables, updatedTables)
    }
  }, [tables]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='sm'
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          Reservation
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Label for="exampleSelect" className='inputfield required-field'>
                Table Number
              </Label>
              <section className="mb-3 input-group-alternative">
                <Select options={tables} value={formik.values?.tableId} className="custom-select" isSearchable={false} styles={selectDropdownStyle}
                  onChange={selectedOption => {
                    formik.setFieldValue("tableId", selectedOption);
                  }}
                  isOptionDisabled={(option) => option.isdisabled}
                />
                {formik.touched?.tableId && formik.errors.tableId && <span className="error-message input-group-alternative">{formik.errors.tableId}</span>}
              </section>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color="outline-primary"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color="primary" type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}

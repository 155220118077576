export default function customers(
  initialState = {
    customers: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_CUSTOMERS':
      return {
        ...initialState,
        customers: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CUSTOMERS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CUSTOMERS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CUSTOMERS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CUSTOMERS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CUSTOMERS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_CUSTOMERS_RESET':
      return {
        ...initialState,
        customers: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}

// Reducers
import user from './reducers/index';
import restaurantAdmin from './reducers/restaurantAdmin/index';
import restaurantAdminTables from './reducers/restaurantAdmin/tables';
import restaurantAdminCategories from './reducers/restaurantAdmin/categories';
import restaurantAdminCustomers from './reducers/restaurantAdmin/customers';
import restaurantAdminTags from './reducers/restaurantAdmin/tags';
import restaurantAdminProducts from './reducers/restaurantAdmin/products';
import restaurantAdminOffers from './reducers/restaurantAdmin/offers';
import restaurantAdminCoupons from './reducers/restaurantAdmin/coupons';
import restaurantAdminMarketings from './reducers/restaurantAdmin/marketings';
import restaurantAdminOrders from './reducers/restaurantAdmin/orders';
import restaurantAdminReports from './reducers/restaurantAdmin/reports';
import restaurantAdminReservations from './reducers/restaurantAdmin/reservations';
import restaurantAdminTransactions from './reducers/restaurantAdmin/transactions';
import restaurantAdminFeedbacks from './reducers/restaurantAdmin/feedbacks';
import restaurantAdminTemplates from './reducers/restaurantAdmin/templates';
import restaurantAdminTemplatesAnalytics from './reducers/restaurantAdmin/templateAnalytics';
import superAdmin from './reducers/superAdmin/index';
import superAdminRestaurants from './reducers/superAdmin/restaurants';
import superAdminSubscriptions from './reducers/superAdmin/subscriptions';
import superAdminPayments from './reducers/superAdmin/payments';

// Utility Packages
import { combineReducers } from 'redux';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  user,
  restaurantAdmin,
  restaurantAdminTables,
  restaurantAdminCategories,
  restaurantAdminCustomers,
  restaurantAdminTags,
  restaurantAdminProducts,
  restaurantAdminOffers,
  restaurantAdminCoupons,
  restaurantAdminMarketings,
  restaurantAdminOrders,
  restaurantAdminReports,
  restaurantAdminReservations,
  restaurantAdminTransactions,
  restaurantAdminFeedbacks,
  restaurantAdminTemplates,
  restaurantAdminTemplatesAnalytics,
  superAdmin,
  superAdminRestaurants,
  superAdminSubscriptions,
  superAdminPayments
});

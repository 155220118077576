import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Form } from "reactstrap";
import { BrandName, paymentFailedMessage, paymentSuccessMessage } from '../../constants/General';
import { removeSessionData } from '../../utils/session';

export default function PaymentStatus() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = new URLSearchParams(location?.search);
  const success = !!Number(pathName.get('success'))

  // State
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    let intervalId;

    if (countdown === 0) {
      if (success) {
        removeSessionData()
      }
      navigate('/login')
    } else {
      intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown, success, navigate]);

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <Form role="form">
              <div className="text-center mb-4">
                {success ? <FontAwesomeIcon color={"#28a745"} icon={faCircleCheck} className='email-verification-check-icon' /> :
                  <FontAwesomeIcon color={"#dc3545"} icon={faCircleXmark} className='email-verification-check-icon' />}
              </div>
              <div className="text-center mb-4">
                <h1>{success ? 'Success!' : 'Failed!'}</h1>
              </div>
              <div className='text-center center-div'>{success ? paymentSuccessMessage : paymentFailedMessage} redirecting in&nbsp;<b>{countdown}sec</b></div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

import moment from "moment";

export default function transactions(
  initialState = {
    transactions: [],
    search: '',
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS':
      return {
        ...initialState,
        transactions: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_START_DATE':
      return {
        ...initialState,
        startDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_END_DATE':
      return {
        ...initialState,
        endDate: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TRANSACTIONS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_TRANSACTIONS_RESET':
      return {
        ...initialState,
        transactions: [],
        search: '',
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}

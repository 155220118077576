import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomPagination from '../../../../components/Pagination';
import { Card, CardHeader, Table, Row, Button, Badge, UncontrolledAlert } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import NoDataLabel from '../../../../components/NoDataLabel';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import ConfirmationModal from '../../../../components/ConfirmationModal'
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions, TemplateUpdateMessage } from '../../../../constants/General';
import TemplateModal from '../../components/TemplateModal';
import { addTemplate, deleteTemplate, editTemplate, getTemplates } from '../../utils/middleware/templates';

export default function Templates() {
  // Store
  const { templates, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminTemplates)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  // Mount
  useEffect(() => {
    getTemplates(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminTemplates.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, page, pageSize, totalCount])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedTemplate(null)
  }
  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedTemplate(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedTemplate(null)
  }

  // Unmout
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminTemplates.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <UncontrolledAlert color="light">
          {TemplateUpdateMessage}
        </UncontrolledAlert >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Templates</h3>
                <div className='right-div-wrap'>
                  <SearchInput action={RoutesActions.templates} />
                  <Button color='primary' onClick={() => setShowAddModal(true)}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {templates.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope='col' >Name </th>
                    <th scope='col' >Header </th>
                    <th scope='col' >Body </th>
                    <th scope='col' >Status </th>
                    <th scope='col' >Footer</th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, index) => {
                    return <tr key={index}>
                      <td >{template?.name}</td>
                      <th scope="row">
                        {template?.headerType}
                      </th>
                      <td className='ellipsis-message-column' title={template.body}>{template?.body}</td>
                      <td>
                        <Badge
                          className={
                            template.status === 'APPROVED'
                              ? "badge-success" : template.status === 'REJECTED'
                                ? "badge-danger"
                                : "badge-light"
                          }
                        >
                          {template.status}
                        </Badge>
                      </td>
                      <td className='ellipsis-message-column' title={template.footer}>{template?.footer}</td>
                      <td className="text-right">
                        <div>
                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            setSelectedTemplate(template)
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedTemplate(template)
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminTemplates.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div >

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteTemplate(selectedTemplate?.id, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <TemplateModal open={showAddModal} handleCloseModal={handleCloseAddModal} handleSuccess={(data) => addTemplate(data, search, activeSort, sortOrder, page, handleCloseAddModal)} />}
      {showEditModal && <TemplateModal open={showEditModal} template={selectedTemplate} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editTemplate(selectedTemplate?.id, data, search, activeSort, sortOrder, page, handleCloseEditModal)} />}
    </div >
  )
}

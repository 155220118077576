import { useFormik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Form, Col, Row, Label } from "reactstrap";
import InputField from '../../../../components/InputField';
import { Country, CountryCode, editProfileInputFields, ImageLinks, ImageType, multiSelectDropdownStyle, selectDropdownStyle, serviceThrough, UK_States } from '../../../../constants/General'
import { editRestaurant, setInitialValues } from '../../utils/middleware/restaurant';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { generateDropdownOptionForStates, generateDropdownOptionFromObject, removeEmptyKeysFromObject, setSelectedCountry, setSelectedDropdownForSameValue, uploadImage } from '../../../../services/middleware';
import { handleError } from '../../../../utils/toast';
import { getLocalStorageUserData } from '../../../../utils/session';
import { editProfileValidationSchema } from '../../../../constants/Schemas';
import UploadLogo from '../../../../components/UpdateLogo';

export default function Profile() {
  // Store
  const { restaurant } = useSelector(state => state.restaurantAdmin)

  // State
  const [editable, setEditable] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setInitialValues(restaurant),
    validationSchema: editProfileValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formValues = { ...formik.values, serviceThrough: values.serviceThrough.map(datum => datum.value), phone: `${CountryCode[restaurant?.country]}${values.phone}`, };
      const filterData = removeEmptyKeysFromObject(formValues)
      if (values.logo && !(typeof values.logo === 'string' && ImageLinks.includes(values.logo?.split(":")[0]))) {
        dispatch(Actions.RestaurantAdmin.SetLoading, true)
        const imageResponse = await uploadImage(values.logo, ImageType.LOGO).catch(err => {
          dispatch(Actions.SuperAdmin.SetLoading, false)
          handleError(err)
        })
        filterData.logo = imageResponse.fileLink;
      }
      editRestaurant(getLocalStorageUserData()?.restaurantId, filterData, resetForm, setEditable)
    }
  });

  return (
    <div>
      <Card className="section-primary shadow">
        <CardHeader className="space-between-div table-header-div">
          <h3>My Profile</h3>
        </CardHeader>
        <CardBody className='user-profile-block'>
          <Form role="form">
            <div className='upload-div-wrapper'>
              <div className='image-div'>
                <UploadLogo formik={formik} fieldName="logo" logo={restaurant?.logo} editable={editable} />
              </div>
              <div className='content-div'>
                <p className='user-name'>{restaurant?.name}</p>
                <p>Updates Your Photo and Personal Details</p>
              </div>
            </div>
            <Row>
              <Col md={6}>
                <InputField required disabled={!editable} placeholder="Name" fieldName="name" formik={formik} />
              </Col>
              <Col md={6}>
                <InputField disabled placeholder="Tagline" value={restaurant?.tagLine} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className='inputfield required-field'>
                  Service
                </Label>
                <section className="mb-3 input-group-alternative">
                  <Select className={`custom-select ${!editable && 'dropdown-disabled'}`} isMulti isDisabled={!editable} required value={formik.values.serviceThrough} options={generateDropdownOptionFromObject(serviceThrough)} isSearchable={false} styles={multiSelectDropdownStyle}
                    onChange={selectedOption => {
                      formik.setFieldValue("serviceThrough", selectedOption);
                    }}
                  />
                  {formik.touched?.serviceThrough && formik.errors.serviceThrough && <span className="error-message input-group-alternative">{formik.errors.serviceThrough}</span>}
                </section>
              </Col>
              <Col md={6}>
                <InputField required disabled={!editable} placeholder="Table count" inputType='number' fieldName="tableCount" formik={formik} />
              </Col>
            </Row>
            {editProfileInputFields.map((row, index) => {
              return <Row key={index}>
                <Col md={6}>
                  <InputField required={row?.requiredLeft} disabled={row.fieldNameLeft === 'email' ? true : !editable} placeholder={row.placeholderLeft} dropdownChild={generateDropdownOptionFromObject(serviceThrough)} fieldName={row.fieldNameLeft} inputType={row?.inputTypeLeft} formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField required={row?.requiredRight} disabled={!editable} placeholder={row.placeholderRight} fieldName={row.fieldNameRight} inputType={row?.inputTypeRight} formik={formik} />
                </Col>
              </Row>
            })}
            <Row>
              <Col md={6}>
                <Label className='inputfield'>
                  State
                </Label>
                <section className="mb-3 input-group-alternative">
                  <Select className="custom-select dropdown-disabled" options={generateDropdownOptionForStates(UK_States)} isDisabled value={setSelectedDropdownForSameValue(restaurant?.state)} isSearchable={false} styles={selectDropdownStyle} />
                </section>
              </Col>
              <Col md={6}>
                <InputField disabled placeholder="Zipcode" value={restaurant?.zipCode} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className='inputfield'>
                  Country
                </Label>
                <section className="mb-3 input-group-alternative">
                  <Select className="custom-select dropdown-disabled" options={Country} isDisabled value={setSelectedCountry(restaurant?.country)} isSearchable={false} styles={selectDropdownStyle} />
                </section>
              </Col>
            </Row>
            <div className="text-right">
              {editable ? <>
                <Button color='primary' className="mt-4" type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
                  Update
                </Button><Button color='outline-primary' className="mt-4" type="button" onClick={() => {
                  formik.setValues(setInitialValues(restaurant))
                  setEditable(false)
                }}>
                  Cancel
                </Button>
              </> : <Button color='primary' className="mt-4" type="button" onClick={() => setEditable(true)}>
                Edit
              </Button>}
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, CardHeader, Media, Table, Row, Button, Collapse, CardBody, Label, Col, FormGroup, Input, } from "reactstrap";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import NoDataLabel from "../../../../components/NoDataLabel";
import CustomPagination from "../../../../components/Pagination";
import { RoutesActions, featurePermissions, subscriptionType, } from "../../../../constants/General";
import { Actions } from "../../../../redux/actions";
import { handleSortColumn, sortIcon } from "../../../../services/middleware";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import AddSubscriptionModal from "../../components/AddSubscriptionModal";
import { changeStatusSubscription, getSubscriptions, } from "../../utils/middleware/subscriptions";
import SearchInput from "../../../../components/SearchInput";

export default function Subscriptions() {
  // Store
  const { subscriptions, search, activeSort, sortOrder, totalCount, page } = useSelector((state) => state.superAdminSubscriptions);
  const { pageSize } = useSelector((state) => state.user);
  const settings = useSelector((state) => state.superAdmin.settings);

  // State
  const [showAddModal, setShowAddModal] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openSubscription, setOpenSubscription] = useState();

  // Mount
  useEffect(() => {
    getSubscriptions(search, activeSort, sortOrder, page);
    let pageCount = Math.ceil(totalCount / pageSize);
    if (totalCount && page > pageCount) {
      dispatch(Actions.SuperAdminSubscriptions.SetPage, pageCount);
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize]);

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.SuperAdminRestaurants.Reset), []);

  const middleIndex = Math.ceil(Object.keys(featurePermissions).length / 2);
  const firstColumn = Object.keys(featurePermissions).slice(0, middleIndex);
  const secondColumn = Object.keys(featurePermissions).slice(middleIndex);

  return (
    <>
      <div>
        <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 space-between-div table-header-div">
                  <h3>Subscriptions</h3>
                  <div className="right-div-wrap">
                    <SearchInput action={RoutesActions.subscriptions} />
                    <Button color="primary" onClick={() => setShowAddModal(true)}>
                      Add
                    </Button>
                  </div>
                </CardHeader>
                {subscriptions?.length !== 0 ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col" className="serial-number cursor-pointer" onClick={() => handleSortColumn("", "", "", "subscriptions")}>No.</th> */}
                        <th scope="col" className={`${activeSort === "name" ? "active-sort-column" : "cursor-pointer"}`} onClick={() => handleSortColumn("name", activeSort, sortOrder, "subscriptions")}>Name{" "}<FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                        <th scope="col" className={`${activeSort === "amount" ? "active-sort-column" : "cursor-pointer"}`} onClick={() => handleSortColumn("amount", activeSort, sortOrder, "subscriptions")}>Amount{" "}<FontAwesomeIcon icon={sortIcon(activeSort, 'amount', sortOrder)} /></th>
                        <th scope="col">Status</th>
                        <th scope="col" className={`${activeSort === "type" ? "active-sort-column" : "cursor-pointer"}`} onClick={() => handleSortColumn("type", activeSort, sortOrder, "subscriptions")}>Type{" "}<FontAwesomeIcon icon={sortIcon(activeSort, 'type', sortOrder)} /></th>
                        <th scope="col" className={`${activeSort === "description" ? "active-sort-column" : "cursor-pointer"}`} onClick={() => handleSortColumn("description", activeSort, sortOrder, "subscriptions")}>Description{" "}<FontAwesomeIcon icon={sortIcon(activeSort, 'description', sortOrder)} /></th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map((subscription, index) => {
                        return (
                          <>
                            <tr key={index} className="cursor-pointer" onClick={() => setOpenSubscription(openSubscription === subscription?.id ? "" : subscription?.id)}>
                              {/* <td className="serial-number">{index + 1 + (page - 1) * pageSize}</td> */}
                              <th scope="row">
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {subscription.name}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td>{subscription.amount && `${settings?.currencySign}${subscription.amount}`}</td>
                              <td>
                                <Badge className={subscription.status ? "badge-success" : "badge-danger"}>
                                  {subscription.status ? "Enable" : "Disable"}
                                </Badge>
                              </td>
                              <td>{subscription.type && subscriptionType[subscription.type]}</td>
                              <td className="ellipsis-column" title={subscription.description}>{subscription.description}</td>
                              <td className="text-right">
                                <div className="flex align-items-center justify-content-end">
                                  <label className="custom-toggle">
                                    <Input type="checkbox" className="form-control" name="recommended" checked={subscription.status} onChange={(e) => {
                                      e.stopPropagation()
                                      setStatusModal(true)
                                      setSelectedSubscription(subscription)
                                    }} />
                                    <span className="custom-toggle-slider rounded-circle" />
                                  </label>
                                </div>
                              </td>
                            </tr>
                            <tr key={subscription.id + "-details"} className={openSubscription === subscription?.id ? "" : "d-none"}><td colSpan="10">
                              <Collapse isOpen={openSubscription === subscription?.id}>
                                <Card>
                                  <CardBody>
                                    <>
                                      <Row>
                                        <Col md={6} className="space-between-div mb-2">
                                          <Label>Features</Label>
                                          <Label>Access</Label>
                                        </Col>
                                        <Col md={6}></Col>
                                      </Row>
                                      <Row>
                                        <Col md={6}>
                                          {firstColumn.map((feature) => (
                                            <Row key={feature}>
                                              <Col className="space-between-div">
                                                <Label className="inputfield">{featurePermissions[feature]}</Label>
                                                <FormGroup check inline>
                                                  <Input type="checkbox" disabled checked={subscription?.feature?.[feature] || false} />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          ))}
                                        </Col>
                                        <Col md={6}>
                                          {secondColumn.map((feature) => (
                                            <Row key={feature}>
                                              <Col className="space-between-div">
                                                <Label className="inputfield">{featurePermissions[feature]}</Label>
                                                <FormGroup check inline>
                                                  <Input type="checkbox" disabled checked={subscription?.feature?.[feature] || false} />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          ))}
                                        </Col>
                                      </Row>
                                    </>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataLabel />
                )}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.SuperAdminSubscriptions.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>
      </div>

      {showStatusModal && (<ConfirmationModal open={showStatusModal} handleCloseModal={() => setStatusModal(false)} handleSuccess={() => changeStatusSubscription(selectedSubscription?.id, search, activeSort, sortOrder, page, () => setStatusModal(false))} />)}
      {showAddModal && (<AddSubscriptionModal open={showAddModal} handleCloseModal={() => setShowAddModal(false)} />)}
    </>
  );
}

import React from 'react'
import moment from 'moment';
import { useEffect } from 'react'
import { Badge, Card, CardHeader, Media, Table, Row, } from "reactstrap";
import { useSelector } from 'react-redux'
import { getPayments } from '../../utils/middleware/payments'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoutesActions, SubscriptionPaymentStatus, subscriptionType } from '../../../../constants/General';
import SearchInput from '../../../../components/SearchInput';

export default function Payments() {
  // Store
  const { payments, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.superAdminPayments)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.superAdmin.settings);

  // Mount
  useEffect(() => {
    getPayments(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.SuperAdminPayments.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.SuperAdminPayments.Reset), [])

  return (
    <>
      <div>
        <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="space-between-div table-header-div border-0">
                  <h3 className="mb-0">Payments</h3>
                  <div className="right-div-wrap">
                    <SearchInput action={RoutesActions.payments} />
                  </div>
                </CardHeader>
                {payments?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'payments')}>No.</th> */}
                      <th scope='col' className={`${activeSort === 'restaurantName' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('restaurantName', activeSort, sortOrder, 'payments')}>Restaurant <FontAwesomeIcon icon={sortIcon(activeSort, 'restaurantName', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'subscriptionName' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('subscriptionName', activeSort, sortOrder, 'payments')}>Subsctiption <FontAwesomeIcon icon={sortIcon(activeSort, 'subscriptionName', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'amount' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('amount', activeSort, sortOrder, 'payments')}>Amount <FontAwesomeIcon icon={sortIcon(activeSort, 'amount', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'type' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('type', activeSort, sortOrder, 'payments')}>Subsctiption Type <FontAwesomeIcon icon={sortIcon(activeSort, 'type', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'status' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('status', activeSort, sortOrder, 'payments')}>Status <FontAwesomeIcon icon={sortIcon(activeSort, 'status', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'createdAt' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('createdAt', activeSort, sortOrder, 'payments')}>Transaction Date <FontAwesomeIcon icon={sortIcon(activeSort, 'createdAt', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'startDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('startDate', activeSort, sortOrder, 'payments')}>Start Date <FontAwesomeIcon icon={sortIcon(activeSort, 'startDate', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'endDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('endDate', activeSort, sortOrder, 'payments')}>End Date <FontAwesomeIcon icon={sortIcon(activeSort, 'endDate', sortOrder)} /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment, index) => {
                      return <tr>
                        {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {payment?.restaurant?.name} {payment?.restaurant?.phone && `(${payment?.restaurant?.phone})`}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{payment?.subscription?.name}</td>
                        <td>
                          <Badge className="badge-warning">
                            {`${settings?.currencySign}${payment.amount || 0}`}
                          </Badge></td>
                        <td>{payment?.subscription?.type &&
                          <Badge className="badge-info">
                            {subscriptionType[payment?.subscription?.type]}
                          </Badge>}</td>
                        <td>
                          <Badge className={payment.status === SubscriptionPaymentStatus.DONE ? 'badge-success' : payment.status === SubscriptionPaymentStatus.PENDING ? 'badge-info' : 'badge-danger'}>
                            {payment.status === SubscriptionPaymentStatus.DONE ? 'Done' : payment.status === SubscriptionPaymentStatus.PENDING ? 'Pending' : 'Failed'}
                          </Badge>
                        </td>
                        <td>{payment?.createdAt ? moment(payment.createdAt).format('DD-MM-YYYY') : ''}</td>
                        <td>{payment?.restaurantSubscription?.startDate ? moment(payment.restaurantSubscription.startDate).format('DD-MM-YYYY') : ''}</td>
                        <td>{payment?.restaurantSubscription?.endDate ? moment(payment.restaurantSubscription.endDate).format('DD-MM-YYYY') : ''}</td>
                      </tr>
                    })}
                  </tbody>
                </Table> : <NoDataLabel />}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.SuperAdminPayments.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

import React, { useRef } from 'react';
import { Media } from 'reactstrap';
import { faClose, faImage, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILE_TYPE } from '../../constants/General';

export default function UploadLogo(props) {
  // Props
  const { fieldName, formik, logo, editable = true } = props
  const inputRef = useRef();

  return (
    <div>
      <input
        style={{ display: 'none' }}
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        type="file"
        className="form-control"
        value=''
        accept={FILE_TYPE.map(function (x) {
          return '.' + x;
        })
          .join(',')}
        onChange={(e) => {
          if (e.currentTarget.files && e.currentTarget.files[0]) {
            formik.setFieldValue(fieldName, e.currentTarget.files[0])
          }
        }
        }
      />
      <Media className="align-items-center mb-3">
        <span className={`avatar avatar-logo avatar-xlg rounded-circle relative ${editable && 'cursor-pointer'}`} title={editable ? "Change" : ''} onClick={() => editable && inputRef.current.click()}>
          {(formik?.values?.[fieldName] || logo) ?
            <img alt="..." src={formik?.values?.[fieldName]?.name ? URL.createObjectURL(formik?.values?.[fieldName]) : logo} /> :
            <FontAwesomeIcon icon={faImage} size='3x' />}
          <div className='edit-close-icons'>
            {editable && <div className='icons' >
              <FontAwesomeIcon icon={faPencil} size='sm' />
            </div>}
            {formik?.values?.[fieldName]?.name && editable && <div className='icons ml-2' onClick={(e) => {
              e.stopPropagation()
              formik?.setFieldValue(fieldName, '')
            }}>
              <FontAwesomeIcon icon={faClose} />
            </div>}
          </div>
        </span>
      </Media>
      {(formik?.touched?.[fieldName] || formik?.errors?.[fieldName]) && <div className="error-message">{formik.errors?.[fieldName]}</div>}
    </div >
  )
}

import React, { useState } from 'react'
import { useEffect } from 'react'
import { Badge, Card, CardHeader, Media, Table, Row, Button } from "reactstrap";
import { useSelector } from 'react-redux'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { RoutesActions, SubscriptionPaymentStatus } from '../../../../constants/General';
import { getTransactions } from '../../utils/middleware/transactions';
import SearchInput from '../../../../components/SearchInput';
import DateRangeSelector from '../../components/DateRangeSelector';

export default function Transactions() {
  // Store
  const { transactions, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminTransactions)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [dateRangeSelector, setDateRangeSelector] = useState(false)

  // Mount
  useEffect(() => {
    getTransactions(search, startDate, endDate, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminTransactions.SetPage, pageCount)
    }
  }, [search, startDate, endDate, activeSort, sortOrder, totalCount, page, pageSize])

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminTransactions.Reset), [])

  return (
    <>
      <div>
        <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 space-between-div table-header-div">
                  <h3 className="mb-0">Transactions</h3>
                  <div className="right-div-wrap">
                    <SearchInput action={RoutesActions.transactions} />
                    <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
                      {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                    </Button>
                  </div>
                </CardHeader>
                {transactions?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'transactions')}>No.</th> */}
                      <th scope='col' className={`${activeSort === 'customer' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('customer', activeSort, sortOrder, 'transactions')}>Customer <FontAwesomeIcon icon={sortIcon(activeSort, 'customer', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'transactionDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('transactionDate', activeSort, sortOrder, 'transactions')}>Date <FontAwesomeIcon icon={sortIcon(activeSort, 'transactionDate', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'amount' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('amount', activeSort, sortOrder, 'transactions')}>Amount <FontAwesomeIcon icon={sortIcon(activeSort, 'amount', sortOrder)} /></th>
                      <th scope='col' >Status</th>
                      <th scope='col' className={`${activeSort === 'description' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('description', activeSort, sortOrder, 'transactions')}>Description <FontAwesomeIcon icon={sortIcon(activeSort, 'description', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'orderId' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('orderId', activeSort, sortOrder, 'transactions')}>Order ID <FontAwesomeIcon icon={sortIcon(activeSort, 'orderId', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'tableNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('tableNumber', activeSort, sortOrder, 'transactions')}>Table Number <FontAwesomeIcon icon={sortIcon(activeSort, 'tableNumber', sortOrder)} /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((payment, index) => {
                      return <tr>
                        {/* <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th> */}
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {payment?.phone}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <th>{payment?.transactionDate ? moment(payment.transactionDate).format('DD-MM-YYYY') : ''}</th>
                        <td>{payment?.amount &&
                          <Badge className="badge-warning" style={{ fontSize: 12 }}>
                            {`${settings?.superAdminSetting?.currencySign}${payment.amount}`}
                          </Badge>}</td>
                        <td>
                          <Badge className={payment.status === SubscriptionPaymentStatus.DONE ? 'badge-success' : payment.status === SubscriptionPaymentStatus.PENDING ? 'badge-info' : 'badge-danger'}>
                            {payment.status === SubscriptionPaymentStatus.DONE ? 'Done' : payment.status === SubscriptionPaymentStatus.PENDING ? 'Pending' : 'Failed'}
                          </Badge>
                        </td>
                        <th className='ellipsis-column' title={payment.description}>{payment?.description}</th>
                        <th>{payment?.orderId}</th>
                        <th>{payment?.tableNumber}</th>
                      </tr>
                    })}
                  </tbody>
                </Table> : <NoDataLabel />}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminTransactions.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>

        {dateRangeSelector && <DateRangeSelector
          open={dateRangeSelector}
          handleCloseModal={() => setDateRangeSelector(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => dispatch(Actions.RestaurantAdminTransactions.SetStartDate, date)}
          setEndDate={(date) => dispatch(Actions.RestaurantAdminTransactions.SetEndDate, date)}
        />}
      </div>
    </>
  )
}

// Session
export const setAccessToken = (data) => {
    localStorage.setItem('accessToken', JSON.stringify(data));
};

export const getAccessToken = () => {
    return JSON.parse(localStorage.getItem('accessToken'));
};

export const setLocalStorageUserData = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
};

export const getLocalStorageUserData = () => {
    return JSON.parse(localStorage.getItem('userData'));
};

export const setRememberMeData = (data) => {
    localStorage.setItem('rememberMe', JSON.stringify(data));
};

export const getRememberMeData = () => {
    return JSON.parse(localStorage.getItem('rememberMe'));
};

export const removeRememberMeData = () => {
    localStorage.removeItem('rememberMe');
};

export const removeSessionData = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isAdminUser');
    window.location.href = '/';
};
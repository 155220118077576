export default function tables(
  initialState = {
    tables: [],
    search: '',
    activeSort: 'tableNumber',
    sortOrder: 'ASC',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_TABLES':
      return {
        ...initialState,
        tables: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TABLES_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TABLES_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TABLES_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TABLES_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TABLES_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_TABLES_RESET':
      return {
        ...initialState,
        tables: [],
        search: '',
        activeSort: 'tableNumber',
        sortOrder: 'ASC',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}

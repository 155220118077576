export function restaurantDTO(data) {
  if (data) {
    return {
      id: data?.id || null,
      name: data?.name || null,
      tableCount: data?.tableCount || 0,
      addressLine1: data?.addressLine1 || null,
      addressLine2: data?.addressLine2 || null,
      zipCode: data?.zipCode || null,
      state: data?.state || null,
      country: data?.country || null,
      tagLine: data?.tagLine || null,
      phone: data?.phone?.slice(2) || null,
      email: data?.email || null,
      logo: data?.logo || null,
      latitude: data?.latitude || null,
      longitude: data?.longitude || null,
      vat: data?.vat || null,
      status: data?.status,
      serviceThrough: Array.isArray(data?.serviceThrough)
        ? data.serviceThrough
        : [],
      subscription:
        data?.subscription && Object.keys(data?.subscription)?.length !== 0
          ? {
              id: data?.subscription?.id || 0,
              name: data?.subscription?.name || null,
              type: data?.subscription?.type || null,
              amount: data?.subscription?.amount || null,
              description: data?.subscription?.description || null,
              status: data?.subscription?.status || null,
              startDate: data?.subscription?.startDate || null,
              endDate: data?.subscription?.endDate || null,
              feature:
                typeof data?.subscription?.feature === "string"
                  ? JSON.parse(data?.subscription?.feature)
                  : data?.subscription?.feature || null,
            }
          : null,
    };
  }
}

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, CardBody, Col, Form, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import CreatableSelect from 'react-select/creatable';
import { useFormik } from 'formik';
import { sendPaymentLinkValidationSchema } from '../../../../constants/Schemas';
import { removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { CountryCode, selectDropdownStyle } from '../../../../constants/General';
import { sendPaymentLink } from '../../utils/middleware/orders';
import { useSelector } from 'react-redux';

export default function SendPaymentLink(props) {
  // Props
  const { open, order, handleCloseModal } = props

  // Store
  const { search, startDate, endDate, activeSort, sortOrder, page } = useSelector(state => state.restaurantAdminOrders)
  const { restaurant } = useSelector(state => state.restaurantAdmin)

  const formik = useFormik({
    initialValues: {
      customers: [],
      coupon: '',
    },
    validationSchema: sendPaymentLinkValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      const filterData = removeEmptyKeysFromObject(values)
      filterData['customers'] = Array.isArray(filterData.customers) ? filterData.customers.map(customer => (customer.value.length === 12 ? customer.value : `${CountryCode[restaurant.country]}${customer.value}`)) : [filterData.customers.value]
      sendPaymentLink({ id: order?.orderId, filterData, search, startDate, endDate, activeSort, sortOrder, page, handleCloseModal })
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Send Payment Link
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col>
                  <Label for="exampleSelect" className='inputfield required-field'>
                    Customers
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <CreatableSelect className="custom-select" isMulti placeholder="Type here for add new customer..." value={formik.values.customers} styles={selectDropdownStyle}
                      onChange={selectedOption => formik.setFieldValue("customers", selectedOption)}
                      options={order?.customers.map(customer => ({ label: customer?.phone, value: customer?.phone }))} />
                    {!!formik.errors?.customers?.length && Array.isArray(formik.errors?.customers) ? <span className="error-message input-group-alternative">{formik.errors.customers.map((customer, index) => (`customer ${index + 1} ${customer.value}`)).join(",")}</span>
                      : formik.errors.customers && <span className="error-message input-group-alternative">{formik.errors.customers}</span>}
                  </section>
                </Col>
              </Row>
              <Row>
                <Col> <InputField placeholder="Coupon" fieldName="coupon" formik={formik} /></Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Send
        </Button>
      </div>
    </Modal >
  )
}

import React from "react";
import { Actions } from "../../../../redux/actions";
import { RoutesActions } from "../../../../constants/General";
import CustomPagination from "../../../../components/Pagination";
import NoDataLabel from "../../../../components/NoDataLabel";
import { useSelector } from "react-redux";
import { useState } from "react";
import ConfirmationModal from '../../../../components/ConfirmationModal'
import { Card, CardHeader, Media, Table, Row, Button, } from "reactstrap";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { handleSortColumn, sortIcon } from "../../../../services/middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import SearchInput from "../../../../components/SearchInput";
import { addTag, deleteTag, editTag, getCustomers, getTags } from "../../utils/middleware/tags";
import TagModal from "../../components/TagModal";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomersTagsModal from "../../components/CustomersTagsModal";

const Tags = () => {
  // Store
  const { tags, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminTags)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showCustomersTagsModal, setShowCustomersTagsModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedTag, setSelectedTag] = useState(null)

  // Mount
  useEffect(() => getTags(search, activeSort, sortOrder, page), [search, activeSort, sortOrder, page, pageSize])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedTag(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedTag(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedTag(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminTags.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Tags List</h3>
                <div className="right-div-wrap">
                  <SearchInput action={RoutesActions.tags} />
                  <Button color='primary' onClick={() => {
                    getCustomers()
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {tags.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'tags')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'tagName' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('tagName', activeSort, sortOrder, 'tags')}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'tagName', sortOrder)} /></th>
                    <th scope='col'>Customers</th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {tag.tagName}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>
                        {!!tag?.customers?.length && <Button color="warning" className='action-icon-btn' title='Customers' onClick={() => {
                          setSelectedTag(tag)
                          setShowCustomersTagsModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>}
                      </td>
                      <td className="text-right">
                        {tag?.tagName !== 'Repeat' && tag?.tagName !== 'First Timer' && <>
                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            setSelectedTag(tag)
                            getCustomers()
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedTag(tag)
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </>}
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminTags.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div >

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteTag(selectedTag?.id, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <TagModal open={showAddModal} tag={selectedTag} handleCloseModal={handleCloseAddModal} handleSuccess={(data) => addTag(data, search, activeSort, sortOrder, page, handleCloseAddModal)} />}
      {showEditModal && <TagModal open={showEditModal} tag={selectedTag} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editTag(selectedTag?.id, data, search, activeSort, sortOrder, page, handleCloseEditModal)} />}
      {showCustomersTagsModal && <CustomersTagsModal open={showCustomersTagsModal} customers={selectedTag.customers} handleCloseModal={() => setShowCustomersTagsModal(false)} />}
    </div >
  )
};

export default Tags;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Form, Label, Modal, Row } from "reactstrap";
import API from '../../../../services/api';
import { API_REQUEST, multiSelectDropdownStyle } from '../../../../constants/General';
import { TEMPLATES_API } from '../../../../constants/Configs';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { handleError } from '../../../../utils/toast';
import { templatesListDTO } from '../../utils/dtos/templates';

export default function SendCampaignModal(props) {
  // Props
  const { open, campaign, handleCloseModal, handleSuccess } = props

  // State
  const [templates, setTemplates] = useState([])

  const formik = useFormik({
    initialValues: {
      templateId: '',
    },
    validationSchema: Yup.object({
      templateId: Yup.object().shape({
        label: Yup.string(),
        value: Yup.number(),
      }).strict().required('Template is required field.')
    }),
    onSubmit: (values) => {
      handleSuccess({ templateId: values.templateId?.value })
    }
  });

  const getTemplates = () => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    const params = {
      templateType: campaign?.mediaType === "image" ? "image" : campaign?.mediaType === "pdf" ? "pdf" : "text", page: 1, 'size': 1000, isApproved: true
    }

    API(API_REQUEST.get, TEMPLATES_API, { params })
      .then(res => {
        const rowData = templatesListDTO(res?.data?.data?.rows)
        const templates = rowData?.map(template => ({ value: template.id, label: template.name }))
        setTemplates(templates)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  }

  useEffect(() => getTemplates(), [])

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Select Template
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col >
                  <Label className='inputfield required-field'>
                    Template
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" value={formik.values.templateId} options={templates} isSearchable={false} styles={multiSelectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("templateId", selectedOption);
                      }}
                    />
                    {formik.touched?.templateId && formik.errors.templateId && <span className="error-message input-group-alternative">{formik.errors.templateId}</span>}
                  </section>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}

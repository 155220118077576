import { PRODUCTS_API } from "../../../../constants/Configs"
import { API_REQUEST, ProductFechingTimeout } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize, sortData } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { productsListDTO } from "../dtos/products"

export const getOfferProducts = (search, activeSort, sortOrder, page) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...({ 'productOnOffer': true })
  }

  API(API_REQUEST.get, PRODUCTS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminOffers.SetOffers, productsListDTO(res.data.data.rows))
      filteredData(productsListDTO(res?.data?.data?.rows), search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdminOffers.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addProductOffer = (data, search, activeSort, sortOrder, page, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, PRODUCTS_API, data)
    .then((res) => {
      setTimeout(() => {
        handleSuccess(res)
        handleCloseAddModal()
        getOfferProducts(search, activeSort, sortOrder, page)
      }, ProductFechingTimeout)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updatePrice = (products) => {
  let lowestPrice = ''
  if (products?.length) {
    lowestPrice = products.reduce((lowestPriceProduct, currentProduct) => {
      if (!lowestPriceProduct || currentProduct.price < lowestPriceProduct.price) {
        return currentProduct;
      } else {
        return lowestPriceProduct;
      }
    }, null);
  }
  return (lowestPrice?.price || '')
}

export const getProductsForSelectedCategory = async (selectedCategory, formik) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!selectedCategory ? { 'category': `${selectedCategory}` } : {}),
  }

  await API(API_REQUEST.get, PRODUCTS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminOffers.SetProducts, productsListDTO(res?.data?.data?.rows))
      formik.setFieldValue("price", updatePrice(productsListDTO(res?.data?.data?.rows)))
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getSelectedProductData = (products, selectedProduct) => {
  let selectedData = null
  if (products?.length) {
    products.forEach(product => {
      if (product.retailerId === selectedProduct) {
        selectedData = product
      }
    })
  }
  return selectedData
}

export const selectedProductPayload = (product) => {
  return {
    title: product.name,
    retailerId: product.retailerId,
    imageLink: product.imageLink,
    price: parseInt(product.price),
    categoryName: product.category,
    recommended: product.recommended,
    description: product.description
  }
}

export const filteredData = (offers, search, activeSort, sortOrder, page) => {
  let data = []
  const query = search?.toLowerCase()
  if (offers?.length) {
    data = sortData(offers, activeSort || 'id', sortOrder).filter((item) =>
      item.name.toLowerCase().includes(query) ||
      item.price.toLowerCase().includes(query) ||
      item.salePrice.toLowerCase().includes(query) ||
      item.description.toLowerCase().includes(query)
    );
  }

  dispatch(Actions.RestaurantAdminOffers.SetTotalCount, data?.length)
  getOffersByPagination(data, page)
}


export const getOffersByPagination = (offers, page) => {
  let data = []
  if (offers?.length) {
    offers.forEach((offer, index) => {
      if (index >= ((page - 1) * getPageSize()) && (index < (getPageSize() * page))) {
        data.push(offer)
      }
    })
  }
  dispatch(Actions.RestaurantAdminOffers.SetOffersWithPagination, data)
}

export const handleSort = (param, activeSort, sortOrder) => {
  let paramSortOrder = ''
  if (param) {
    if (activeSort !== param) {
      paramSortOrder = 'ASC';
    } else {
      paramSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
  }
  dispatch(Actions.RestaurantAdminOffers.SetActiveSort, param);
  dispatch(Actions.RestaurantAdminOffers.SetSortOder, paramSortOrder);
}
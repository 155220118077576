import { useState } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import { getLocalStorageUserData, removeSessionData } from "../../utils/session";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faKey, faAngleRight, faAngleDown, faQrcode } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../ChangePassword";
import { isAllowFeature } from "../../services/middleware";
import ViewQRCode from "../../pages/Restaurant/components/viewQRCode";
import logoRed from '../../assets/images/logo-red.svg';
import logoShort from '../../assets/images/logo-short.svg';

const RestaurantSidebar = (props) => {
  // Store
  const { restaurant } = useSelector(state => state.restaurantAdmin)
  const isAdminUser = useSelector(state => state.user.isAdminUser)

  // State
  const { pathname } = useLocation();
  let route = pathname.split("/")[pathname.split("/")?.length - 1]
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState();
  const [viewQRModal, setViewQRModal] = useState(false)
  const [subMenuCollapsed, setSubMenuCollapsed] = useState({
    'Offers and Discounts': route === 'coupons'
  });

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const toggleSubMenuCollapse = (name) => {
    setSubMenuCollapsed((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const closeSubMenuCollapse = () => {
    setSubMenuCollapsed({});
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.subMenu) {
        // Render a sub-menu item with a collapse component
        return !(prop.subMenu.every(path => !isAllowFeature(path.path.slice(1)))) && <NavItem key={key}>
          <div
            className="nav-link d-flex cursor-pointer"
            tag={NavLinkRRD}
            onClick={() => toggleSubMenuCollapse(prop.name)}
          >
            <i className={prop.icon} />
            <span className="menu-name">{prop.name}</span>
            <FontAwesomeIcon color="#5e72e4" className="ml-3" icon={subMenuCollapsed[prop.name] ? faAngleDown : faAngleRight} />
          </div>
          <Collapse isOpen={subMenuCollapsed[prop.name]} className="">
            {prop.subMenu.map((subProp, subKey) => (
              <div className="nav-item" key={subKey} >
                {isAllowFeature(subProp.path.slice(1)) && <NavLink
                  to={prop.layout + subProp.layout + subProp.path}
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeclassname="active submenu-margin-left"
                  className="submenu-margin-left"
                >
                  <i className={subProp.icon} />
                  <span className="menu-name">{subProp.name}</span>
                </NavLink>}
              </div>
            ))}
          </Collapse>
        </NavItem>
      } else {
        // Render a regular menu item
        return isAllowFeature(prop.path.slice(1)) && <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={() => {
              closeCollapse()
              closeSubMenuCollapse()
            }}
            activeclassname="active"
          >
            <i className={prop.icon} />
            <span className="menu-name">{prop.name}</span>
          </NavLink>
        </NavItem>
      }
    });
  };

  const { routes } = props;
  function collapse_sidebar() {
    var body_el = document.querySelector('body');
    body_el.classList.toggle("collapse-side-menu")
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left site-sidebar"
      expand="lg"
      id="sidenav-main"
    >
      {/* Toggler */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleCollapse}
      >
        <span className="navbar-toggler-icon">
          <svg width="28" height="18" viewBox="0 0 28 18">
            <rect width="28" height="2" fill="#5d5c61" />
            <rect y="8" width="16" height="2" fill="#5d5c61" />
            <rect y="16" width="28" height="2" fill="#5d5c61" />
          </svg>
        </span>
      </button>
      {/* Brand */}
      <div className="sidebar-topbar">
        <Media className="logo-outer-wrap">
          <span className="logo-wrapper">
            {restaurant?.logo ? <img alt="..." src={restaurant?.logo} /> :
              <>
                <img alt="logoRed" src={logoRed} className="normal-logo" />
                <img alt="logoShort" src={logoShort} className="mini-logo" />
              </>}
          </span>
        </Media>

        <div className="hamburger-menu" onClick={() => collapse_sidebar()}>
          {/* <FontAwesomeIcon icon={faCircleDot} /> */}
          <span className="outer-circle"><em></em></span>
        </div>
      </div>
      {/* User */}
      <Nav className="align-items-center d-lg-none">
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                {restaurant?.logo ? <img alt="..." src={restaurant?.logo} /> :
                  <FontAwesomeIcon icon={faImage} />}
              </span>
              <Media className="ml-2 d-none d-lg-block">
                <span className="mb-0 text-sm font-weight-bold">
                  {restaurant?.name || getLocalStorageUserData()?.name}
                </span>
              </Media>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" end>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">Welcome!</h6>
            </DropdownItem>
            {!isAdminUser && <DropdownItem to="/admin/profile" tag={Link}>
              <i className="ni ni-single-02" />
              <span>My profile</span>
            </DropdownItem>}
            <DropdownItem tag={Link} onClick={() => setChangePasswordModal(true)}>
              <FontAwesomeIcon icon={faKey} />
              <span>Change password</span>
            </DropdownItem>
            <DropdownItem tag={Link} onClick={() => setViewQRModal(true)}>
              <FontAwesomeIcon icon={faQrcode} />
              <span>Restaurant QR Code</span>
            </DropdownItem>
            <DropdownItem to="/admin/settings" tag={Link}>
              <i className="ni ni-settings-gear-65" />
              <span>Settings</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="#" onClick={() => removeSessionData()}>
              <i className="ni ni-button-power" />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Navigation */}
        <Nav navbar>{createLinks(routes)}</Nav>
      </Collapse>

      {changePasswordModal && <ChangePassword open={changePasswordModal} handleCloseModal={() => setChangePasswordModal(false)} />}
      {viewQRModal && <ViewQRCode open={viewQRModal} restaurantName={getLocalStorageUserData()?.name} restaurantPhone={getLocalStorageUserData()?.phone} handleCloseModal={() => setViewQRModal(false)} />}
    </Navbar>
  );
};

RestaurantSidebar.defaultProps = {
  routes: [{}]
};

RestaurantSidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default RestaurantSidebar;

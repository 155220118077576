export function subscriptionsListDTO(data) {
  let subscriptions = [];

  if (data?.length) {
    subscriptions = data.map((subscription) => ({
      id: subscription?.id || null,
      name: subscription?.name || null,
      type: subscription?.type || null,
      amount: subscription?.amount || null,
      description: subscription?.description || null,
      feature:
        typeof subscription?.feature === "string"
          ? JSON.parse(subscription?.feature)
          : subscription?.feature || null,
      status: subscription?.status,
    }));
  }

  return subscriptions;
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomPagination from '../../../../components/Pagination';
import { Card, CardHeader, Media, Table, Row, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCopy, faEye, faFilePdf, faImage, faPaperPlane, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import NoDataLabel from '../../../../components/NoDataLabel';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { addCampaign, deleteCampaign, editCampaign, getCampaigns, getCustomers, getTags, sendCampaign } from '../../utils/middleware/marketings';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import CampaignModal from '../../components/CampaignModal';
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions } from '../../../../constants/General';
import CustomersTagsModal from '../../components/CustomersTagsModal';
import moment from 'moment';
import SendCampaignModal from '../../components/SendCampaignModal';

export default function Marketing() {
  // Store
  const { campaigns, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminMarketings)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showCustomersTagsModal, setShowCustomersTagsModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSendCampaignModal, setShowSendCampaignModal] = useState(false)
  const [showRerunCampaignModal, setShowRerunCampaignModal] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  // Mount
  useEffect(() => {
    getCampaigns(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminMarketings.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, page, pageSize, totalCount])

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCampaign(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedCampaign(null)
  }

  const handleCloseSendModal = () => {
    setShowSendCampaignModal(false)
    setSelectedCampaign(null)
  }

  const handleCloseRerunModal = () => {
    setShowRerunCampaignModal(false)
    setSelectedCampaign(null)
  }

  const openPreview = (campaign) => {
    dispatch(Actions.User.SetImagePreview, true)
    dispatch(Actions.User.SetImageLink, campaign.media)
    dispatch(Actions.User.SetFileType, campaign.mediaType)
  }

  // Unmout
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminMarketings.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Campaigns</h3>
                <div className='right-div-wrap'>
                  <SearchInput action={RoutesActions.marketings} />
                  <Button color='primary' onClick={() => {
                    getTags()
                    getCustomers()
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {campaigns.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'marketings')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('name', activeSort, sortOrder, 'marketings')}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'message' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('message', activeSort, sortOrder, 'marketings')}>Message <FontAwesomeIcon icon={sortIcon(activeSort, 'message', sortOrder)} /></th>
                    <th scope='col' >Customers/Tags</th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns.map((campaign, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle mr-3">
                            {campaign.mediaType === 'pdf' ? <FontAwesomeIcon className="cursor-pointer" icon={faFilePdf} size="xl" onClick={() => openPreview(campaign)} /> :
                              campaign?.media ? <img alt="Logo" className="avatar rounded-circle cursor-pointer" src={campaign.media}
                                onClick={() => openPreview(campaign)} /> :
                                <FontAwesomeIcon icon={faImage} />}
                          </span>
                          <Media>
                            <span className="mb-0 text-sm ellipsis-column">
                              {campaign.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className='ellipsis-message-column' title={campaign.message}>{campaign.message}</td>
                      <td>
                        {(!!campaign?.tags?.length || !!campaign?.customers?.length) && <Button color="warning" className='action-icon-btn' title='Customers/Tags' onClick={() => {
                          setSelectedCampaign(campaign)
                          setShowCustomersTagsModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>}
                      </td>
                      <td className="text-right">
                        <div>
                          <Button color="info" className='action-icon-btn' title='Send' disabled={!(!campaign?.campaignIssue ||
                            (campaign?.campaignIssue?.campaignSentDate &&
                              !moment(campaign?.campaignIssue?.campaignSentDate).isSame(moment(), 'day')))} onClick={() => {
                                setSelectedCampaign(campaign)
                                setShowSendCampaignModal(true)
                              }}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                          <Button color="info" className='action-icon-btn' title='Send again from the point of the last pause' disabled={(!campaign?.campaignIssue ||
                            (campaign?.campaignIssue?.campaignSentDate &&
                              !moment(campaign?.campaignIssue?.campaignSentDate).isSame(moment(), 'day')))}
                            onClick={() => {
                              setSelectedCampaign(campaign)
                              setShowRerunCampaignModal(true)
                            }}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                          </Button>
                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            getTags()
                            getCustomers()
                            setSelectedCampaign(campaign)
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="info" className='action-icon-btn' title='Copy' onClick={() => {
                            setSelectedCampaign(campaign)
                            getTags()
                            getCustomers()
                            setShowAddModal(true)
                          }}>
                            <FontAwesomeIcon icon={faCopy} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedCampaign(campaign)
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                        {!(!campaign?.campaignIssue ||
                          (campaign?.campaignIssue?.campaignSentDate &&
                            !moment(campaign?.campaignIssue?.campaignSentDate).isSame(moment(), 'day'))) && < span className='warning-message'>Campaign issue detected. Please re-run to reach remaining customers.</span>}
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminMarketings.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteCampaign(selectedCampaign?.id, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showRerunCampaignModal && <ConfirmationModal open={showRerunCampaignModal} handleCloseModal={handleCloseRerunModal} handleSuccess={() => sendCampaign(selectedCampaign?.id, { templateId: selectedCampaign?.draftTemplateId }, search, activeSort, sortOrder, page, handleCloseRerunModal)} />}
      {showSendCampaignModal && <SendCampaignModal open={showSendCampaignModal} campaign={selectedCampaign} handleCloseModal={handleCloseSendModal} handleSuccess={(data) => sendCampaign(selectedCampaign?.id, data, search, activeSort, sortOrder, page, handleCloseSendModal)} />}
      {showAddModal && <CampaignModal open={showAddModal} campaign={selectedCampaign} handleCloseModal={() => setShowAddModal(false)} handleSuccess={(data) => addCampaign(data, search, activeSort, sortOrder, page, () => setShowAddModal(false))} />}
      {showEditModal && <CampaignModal open={showEditModal} campaign={selectedCampaign} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editCampaign(selectedCampaign?.id, data, search, activeSort, sortOrder, page, handleCloseEditModal)} />}
      {showCustomersTagsModal && <CustomersTagsModal open={showCustomersTagsModal} customers={selectedCampaign.customers} tags={selectedCampaign.tags} handleCloseModal={() => setShowCustomersTagsModal(false)} />}
    </div >
  )
}

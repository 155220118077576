import { Provider } from 'react-redux';
import { Store } from './redux/configureStore';
import { ToastContainer } from 'react-toastify';
import Router from './router';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/plugins/nucleo/css/nucleo.css";
import "./assets/scss/dashboard-react.scss";

import "react-datetime/css/react-datetime.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "./assets/css/custom.css";
import "./assets/css/dashboard-react.css";
import './App.css';
import "./assets/scss/custom-theme/main.scss";

function App() {
  return (
    <Provider store={Store}>
      <ToastContainer newestOnTop />
      <Router />
    </Provider>)
}

export default App;

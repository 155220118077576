export default function user(
  initialState = {
    isAdminUser: false,
    imagePreview: false,
    imageLink: '',
    fileType: 'image',
    pageSize: 10
  },
  action
) {
  switch (action.type) {
    case 'SET_USER_IS_ADMIN_USER':
      return {
        ...initialState,
        isAdminUser: action.payload,
      };

    case 'SET_USER_IMAGE_PREVIEW':
      return {
        ...initialState,
        imagePreview: action.payload,
      };

    case 'SET_USER_IMAGE_LINK':
      return {
        ...initialState,
        imageLink: action.payload,
      };

    case 'SET_USER_FILE_TYPE':
      return {
        ...initialState,
        fileType: action.payload,
      };

    case 'SET_USER_PAGE_SIZE':
      return {
        ...initialState,
        pageSize: action.payload,
      };

    case 'USER_RESET':
      return {
        ...initialState,
        isAdminUser: false,
        imagePreview: false,
        imageLink: '',
        fileType: 'image',
        pageSize: 10
      };

    default:
      return initialState;
  }
}

export function reportsListDTO(data) {
  let reports = [];

  if (data?.length) {
    reports = data.map((report) => ({
      productName: report?.productName || null,
      productRetailerId: report?.productRetailerId || null,
      productCount: report?.productCount || 0,
      categoryCount: report?.categoryCount || 0,
      startDate: report?.startDate || null,
      endDate: report?.endDate || null,
      name: report?.name || null,
      orderCount: report?.orderCount || 0,
      revenue: report?.revenue || 0,
      tax: report.revenue && report.vat ? ((report?.revenue * report?.vat) / 100).toFixed(2) : 0,
      vat: report?.vat || null,
      productRetailerIds: report?.productRetailerIds?.length || 0,
    }))
  }

  return reports;
}
import { RESTAURANT_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { setSelectedService } from "../../../../services/middleware"
import { setLocalStorageUserData, getLocalStorageUserData } from "../../../../utils/session"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { restaurantDTO } from "../dtos/restaurant"

export const getRestaurant = (id) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, `${RESTAURANT_API}/${id}`)
    .then(res => {
      dispatch(Actions.RestaurantAdmin.SetRestaurant, restaurantDTO(res?.data?.data))
      setLocalStorageUserData({ ...getLocalStorageUserData(), phone: res?.data?.data?.phone })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editRestaurant = (id, data, resetForm, setEditable) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${RESTAURANT_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetRestaurant, restaurantDTO(data))
      getRestaurant(id)
      setEditable(false)
      resetForm({ values: '' })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const setInitialValues = (restaurant) => {
  return {
    name: restaurant?.name,
    addressLine1: restaurant?.addressLine1,
    addressLine2: restaurant?.addressLine2,
    phone: restaurant?.phone,
    email: restaurant?.email,
    serviceThrough: setSelectedService(restaurant?.serviceThrough),
    tableCount: restaurant?.tableCount,
    logo: restaurant?.logo,
  }
}
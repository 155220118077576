import { useState } from "react";
import { Button, Row } from "reactstrap";
import { faBarChart, faPieChart, faSterlingSign, } from "@fortawesome/free-solid-svg-icons";
import { dispatch } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import { handleError } from "../../../../utils/toast";
import API from "../../../../services/api";
import { API_REQUEST, calenderViewMode } from "../../../../constants/General";
import { SUPER_ADMIN_DASHBOARD_API } from "../../../../constants/Configs";
import { useEffect } from "react";
import { dashboardDataDTO } from "../../utils/dtos/dashboard";
import DateRangeSelector from "../../../Restaurant/components/DateRangeSelector/index.jsx";
import Cards from "../../../../components/Cards";
import { useSelector } from "react-redux";
import moment from "moment";

export default function Dashboard() {
  // Store
  const settings = useSelector((state) => state.superAdmin.settings);

  // State
  const [dashboardData, setDashboardData] = useState(null)
  const [dateRangeSelector, setDateRangeSelector] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf('month').toISOString())
  const [endDate, setEndDate] = useState(moment().endOf('month').toISOString())

  useEffect(() => getDashboardData(), [startDate, endDate])

  const getDashboardData = () => {
    dispatch(Actions.SuperAdmin.SetLoading, true)
    API(API_REQUEST.get, `${SUPER_ADMIN_DASHBOARD_API}?startDate=${startDate}&endDate=${endDate}`)
      .then(res => {
        setDashboardData(dashboardDataDTO(res?.data?.data))
        dispatch(Actions.SuperAdmin.SetLoading, false)
      })
      .catch(err => {
        handleError(err)
        dispatch(Actions.SuperAdmin.SetLoading, false)
      })
  }

  return (
    <div>
      <div className="card-list-wrapper">
        <div className="flex-end mb-4 ">
          <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
            {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
          </Button>
        </div>
        <div>
          {/* Card stats */}
          <Row>
            <Cards label="Restaurant Count" value={dashboardData?.restaurantDetails?.count} icon={faBarChart} color="purple" xl={4} />
            <Cards label="Subscriptions Count" value={dashboardData?.subscriptionDetails.count} icon={faPieChart} color="green" xl={4} />
            <Cards label="Total Payments" value={settings?.currencySign + (dashboardData?.paymentDetails?.count || 0).toFixed(2)} icon={faSterlingSign} color="orange" xl={4} />
          </Row>
        </div>
      </div>

      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        viewMode={calenderViewMode.day}
        setStartDate={(date) => setStartDate(date)}
        setEndDate={(date) => setEndDate(date)}
      />}
    </div>
  )
}

// reactstrap components
import { Row, Col } from "reactstrap";
import { BrandName } from "../../constants/General";

const Footer = () => {
  return (
    <footer className="footer site-footer">
      <div className="footer-wrap">
        <Row className="align-items-center justify-content-xl-between">
          <Col>
            <div className="copyright text-center text-muted">
              © {new Date().getFullYear()}{" "} <span className="font-weight-600">All rights reserved by</span>
              <a className="font-weight-bold ml-1" href="/admin/dashboard">
                {BrandName}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </footer >
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Card, CardHeader, Media, Table, Row, Button, Input, } from "reactstrap";
import ViewQRCode from "../../components/viewQRCode";
import { addTable, deleteTable, editTable, getTables, } from "../../utils/middleware/tables";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import NoDataLabel from "../../../../components/NoDataLabel";
import { getLocalStorageUserData } from "../../../../utils/session";
import { getRestaurant } from "../../utils/middleware/restaurant";
import CustomPagination from "../../../../components/Pagination";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SearchInput from "../../../../components/SearchInput";
import { RoutesActions } from "../../../../constants/General";

export default function Tables() {
  // Store
  const { tables, search, activeSort, sortOrder, totalCount, page } =
    useSelector((state) => state.restaurantAdminTables);
  const { pageSize } = useSelector((state) => state.user);

  // State
  const [showAddModal, setShowAddModal] = useState(false);
  const [viewQRModal, setViewQRModal] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);

  // Mount
  useEffect(() => {
    getTables(search, activeSort, sortOrder, page);
    let pageCount = Math.ceil(totalCount / pageSize);
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminTables.SetPage, pageCount);
    }
  }, [search, activeSort, sortOrder, page, pageSize, totalCount]);

  // Unmount
  useEffect(
    () => () => {
      getRestaurant(getLocalStorageUserData()?.restaurantId);
      dispatchNoPayload(Actions.RestaurantAdminTables.Reset);
    },
    []
  );

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3>Tables</h3>
                <div className="right-div-wrap">
                  <SearchInput action={RoutesActions.tables} />
                  <Button color="primary" onClick={() => setShowAddModal(true)}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {tables.length !== 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Table Number</th>
                      <th scope="col">QR Code</th>
                      <th scope="col">Availability</th>
                      <th scope="col" className="text-right">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tables.map((table, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {table.tableNumber}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>
                            <Button
                              color="outline-primary"
                              disabled={
                                !table.status ||
                                (!table.isAvailable && !table.status)
                              }
                              size="sm"
                              onClick={() => {
                                setViewQRModal(true);
                                setSelectedTable(table);
                              }}
                            >
                              Show
                            </Button>
                          </td>
                          <td>
                            <Badge
                              className={
                                table.isAvailable
                                  ? "badge-success"
                                  : "badge-light"
                              }
                            >
                              {table.isAvailable ? "Available" : "Booked"}
                            </Badge>
                          </td>
                          <td className="text-right">
                            <div className="flex align-items-center justify-content-end">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  className="form-control"
                                  name="recommended"
                                  checked={table.status}
                                  disabled={!table.isAvailable}
                                  onChange={() => {
                                    setStatusModal(true);
                                    setSelectedTable(table);
                                  }}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                              {page === Math.ceil(totalCount / pageSize) &&
                                index === tables.length - 1 && (
                                  <Button
                                    color="danger"
                                    className="ml-1 action-icon-btn"
                                    title="Delete"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setSelectedTable(table);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <NoDataLabel />
              )}
              <CustomPagination
                totalCount={totalCount}
                page={page}
                handlePageClick={({ selected }) =>
                  dispatch(Actions.RestaurantAdminTables.SetPage, selected + 1)
                }
              />
            </Card>
          </div>
        </Row>
      </div>
      <ConfirmationModal
        open={showDeleteModal}
        handleCloseModal={() => setShowDeleteModal(false)}
        handleSuccess={() =>
          deleteTable(search, activeSort, sortOrder, page, setShowDeleteModal)
        }
      />
      <ConfirmationModal
        open={showAddModal}
        handleCloseModal={() => setShowAddModal(false)}
        handleSuccess={() =>
          addTable(search, activeSort, sortOrder, page, () =>
            setShowAddModal(false)
          )
        }
      />
      <ConfirmationModal
        open={showStatusModal}
        handleCloseModal={() => setStatusModal(false)}
        handleSuccess={() =>
          editTable(
            selectedTable?.id,
            { status: selectedTable?.status ? 0 : 1 },
            search,
            activeSort,
            sortOrder,
            page,
            () => setStatusModal(false)
          )
        }
      />
      {viewQRModal && (
        <ViewQRCode
          open={viewQRModal}
          isTableNumerRequired
          table={selectedTable}
          handleCloseModal={() => setViewQRModal(false)}
        />
      )}
    </div>
  );
}

import { SUBSCRIPTION_PAYMENT_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError } from "../../../../utils/toast"
import { paymentsListDTO } from "../dtos/payments"

export const getPayments = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, `${SUBSCRIPTION_PAYMENT_API}/payments`, { params })
    .then(res => {
      dispatch(Actions.SuperAdminPayments.SetPayments, paymentsListDTO(res?.data?.data?.rows))
      dispatch(Actions.SuperAdminPayments.SetTotalCount, res?.data?.data?.count || 0)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}
export default function templates(
  initialState = {
    templates: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_TEMPLATES':
      return {
        ...initialState,
        templates: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TEMPLATES_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TEMPLATES_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TEMPLATES_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TEMPLATES_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_TEMPLATES_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_TEMPLATES_RESET':
      return {
        ...initialState,
        templates: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}

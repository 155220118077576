export default function restaurantAdmin(
  initialState = {
    loading: false,
    restaurant: null,
    settings: {
      superAdminSetting: { currencySign: '' }
    },
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_LOADING':
      return {
        ...initialState,
        loading: action.payload,
      };

    case 'SET_RESTAURANT_DATA':
      return {
        ...initialState,
        restaurant: action.payload,
      };

    case 'SET_RESTAURANT_SETTINGS_FIELDS':
      return {
        ...initialState,
        settings: action.payload,
      };

    case 'RESTAURANT_ADMIN_RESET':
      return {
        ...initialState,
        loading: false,
        restaurant: null,
        settings: {
          superAdminSetting: { currencySign: '' }
        },
      };

    default:
      return initialState;
  }
}

import { ORDERS_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { transactionsListDTO } from "../dtos/transactions"

export const getTransactions = (search, startDate, endDate, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!startDate ? { startDate } : {}),
    ...(!!endDate ? { endDate } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, `${ORDERS_API}/transactions`, { params })
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdminTransactions.SetTransactions, transactionsListDTO(res?.data?.data?.rows))
      dispatch(Actions.RestaurantAdminTransactions.SetTotalCount, res?.data?.data?.count || 0)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}
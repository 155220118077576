import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import AdminSidebar from "../../../../components/AdminSidebar";
import Header from "../../../../components/Navbars";
import Footer from "../../../../components/Footers";
import { AdminRoutes } from "../../../../constants/Routes";
import Loader from "../../../../components/Loader";
import { useEffect } from "react";
import { getMessages, getSettings } from "../../utils/middleware/settings";

const Dashboard = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const loading = useSelector(state => state.superAdmin.loading)

  const getBrandText = () => {
    let route = AdminRoutes.find((value) => location.pathname.indexOf(value.layout + value.path) !== -1)
    return route?.name || "Brand";
  };

  // Mount
  useEffect(() => {
    getSettings()
    getMessages()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <AdminSidebar
        routes={AdminRoutes}
        logo={{
          innerLink: "/admin/dashboard",
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <header className="site-header">
          <Header brandText={getBrandText(location.pathname)} />
        </header>
        <div className="main-content-wrap">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;

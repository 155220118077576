import { RESTAURANT_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import API from "../../../../services/api"
import { Actions } from "../../../../redux/actions"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { restaurantsListDTO } from "../dtos/restaurant"
import { getPageSize } from "../../../../services/middleware"

export const getRestaurants = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, RESTAURANT_API, { params })
    .then(res => {
      dispatch(Actions.SuperAdminRestaurants.SetRestaurants, restaurantsListDTO(res?.data?.data?.rows))
      dispatch(Actions.SuperAdminRestaurants.SetTotalCount, res.data.data.count)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const addRestaurant = (userData, search, activeSort, sortOrder, page, resetForm, handleCloseModal) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.post, RESTAURANT_API, userData)
    .then((res) => {
      handleSuccess(res)
      handleCloseModal()
      resetForm({ values: '' });
      getRestaurants(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}


export const deleteRestaurant = (id, search, activeSort, sortOrder, page, setShowDeleteModal) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${RESTAURANT_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      setShowDeleteModal(false)
      getRestaurants(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const editRestaurant = (id, data, search, activeSort, sortOrder, page, handleCloseModal, resetForm) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.put, `${RESTAURANT_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseModal()
      if (resetForm) {
        resetForm({ values: '' });
      }
      getRestaurants(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}
export function feedbacksListDTO(data) {
  let feedbacks = [];

  if (data?.length) {
    feedbacks = data.map((feedback) => ({
      id: feedback?.id || 0,
      createdAt: feedback?.createdAt || null,
      customerNumber: feedback?.customerNumber || null,
      customerName: feedback?.customerName || null,
      orderId: feedback?.orderId || null,
      tableNumber: feedback?.tableNumber || null,
      message: feedback?.message || null,
      rating: feedback?.rating || 0,
      total: feedback?.total || null,
    }))
  }

  return feedbacks;
}
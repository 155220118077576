import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_API } from '../../constants/Configs';
import { Button, Card, CardBody, Form } from "reactstrap";
import InputField from '../../components/InputField';
import { handleError, handleSuccess } from '../../utils/toast';
import API from '../../services/api';
import { forgotPasswordValidationSchema } from '../../constants/Schemas';
import { API_REQUEST, } from '../../constants/General';
import Loader from '../../components/Loader';
import { trimObjectValues } from '../../services/middleware';
import logo from '../../assets/images/logo-white.svg';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const initialValues = {
    email: '',
  };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values)
      sendForgotPasswordLink({
        email: values.email,
      }, resetForm)
    }
  });

  const sendForgotPasswordLink = (payload, resetForm) => {
    setLoading(true)
    API(API_REQUEST.post, FORGOT_PASSWORD_API, payload)
      .then((res) => {
        handleSuccess(res)
        resetForm({ values: '' });
        setLoading(false)
        navigate('/login');
      })
      .catch((error) => {
        handleError(error)
        setLoading(false)
      })
  }

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span><img src={logo} alt="logo" /></span>
        </div>
        <Card className="shadow">
          {loading && <Loader />}
          <CardBody>
            <div className="mb-4">
              <h1>Forgot Password</h1>
            </div>
            <Form role="form">
              <InputField placeholder="Email" fieldName="email" formik={formik} />
              <div className="btn-wrap">
                <Button color='primary' disabled={!!Object.values(formik.errors)?.length} type="button" onClick={formik.handleSubmit} >
                  Send Link
                </Button>
              </div>
            </Form>
            <div className="links-wrap">
              <Link className="secondary-link" to="/register">
                Create new account
              </Link>
              <Link className="secondary-link" to="/login">
                Already have an account?
              </Link>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>)
}

import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  ImageLinks,
  ImageType,
  selectDropdownStyle,
} from "../../../../constants/General";
import { editProductValidationSchema } from "../../../../constants/Schemas";
import { Actions } from "../../../../redux/actions";
import {
  removeEmptyKeysFromObject,
  trimObjectValues,
  uploadImage,
} from "../../../../services/middleware";
import { dispatch } from "../../../../utils/store";
import { handleError } from "../../../../utils/toast";
import { addProduct } from "../../utils/middleware/products";
import InputField from "../../../../components/InputField";
import UploadLogo from "../../../../components/UpdateLogo";

export default function EditProductModal(props) {
  // Props
  const { open, product, handleCloseModal } = props;

  // Store
  const { categories, search, activeSort, sortOrder, page } = useSelector(
    (state) => state.restaurantAdminProducts
  );

  const setCategoriesData = (category) => {
    let subCategories = []
    let subCategory = null
    let parentCategory = { label: category, value: category }
    let parent = categories.find((cat) => cat.value === category);
    if (!parent?.isParentCategory) {
      for (const parent of categories) {
        const find = parent.subCategories.find(cat => cat.value === category);
        if (!!find) {
          parentCategory = { label: parent.value, value: parent.value }
          subCategory = find
          subCategories = parent.subCategories
          break;
        }
      }
    }
    return { parentCategory, subCategory, subCategories }
  };

  // State
  const [subCategories, setSubCategories] = useState(setCategoriesData(product.category)?.subCategories);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: product?.name || "",
      imageLink: product?.imageLink || "",
      description: product?.description || "",
      price: product?.price || "",
      categoryName: setCategoriesData(product?.category)?.parentCategory || "",
      subCategoryName: setCategoriesData(product?.category)?.subCategory || "",
      recommended: product?.recommended || false,
    },
    validationSchema: editProductValidationSchema,
    onSubmit: async (values) => {
      trimObjectValues(values, "imageLink");
      dispatch(Actions.RestaurantAdmin.SetLoading, true);
      const formValues = {
        retailerId: product.retailerId,
        title: values.title,
        description: values.description,
        imageLink: values.imageLink,
        price: parseInt(values?.price),
        categoryName: !subCategories?.length
          ? values.categoryName?.value
          : values.subCategoryName.value,
        recommended: values.recommended,
      };
      if (
        !(
          typeof values.imageLink === "string" &&
          ImageLinks.includes(values.imageLink?.split(":")[0])
        )
      ) {
        const imageResponse = await uploadImage(
          values.imageLink,
          ImageType.PRODUCT_IMAGE
        ).catch((err) => {
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
          handleError(err);
        });
        formValues.imageLink = imageResponse.fileLink;
      }
      delete values.subCategoryName
      if (!formValues.description) {
        formValues.description = `Elevate your dining at home experience with our gourmet ${formValues.title}, a delectable treat that won't strain your budget at ${formValues.price}.`
      }
      const filterData = removeEmptyKeysFromObject(formValues);
      addProduct(
        filterData,
        search,
        activeSort,
        sortOrder,
        page,
        handleCloseModal
      );
    },
  });


  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size="lg"
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Edit Product
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </button>
      </div>
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <UploadLogo
                formik={formik}
                fieldName="imageLink"
                logo={product?.imageLink}
              />
              <Row>
                <Col md={6}>
                  <InputField
                    required
                    placeholder="Title"
                    fieldName="title"
                    formik={formik}
                  />
                </Col>
                <Col md={6}>
                  <InputField
                    placeholder="Description"
                    fieldName="description"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label
                    for="exampleSelect"
                    className="inputfield required-field"
                  >
                    Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select
                      className="custom-select"
                      options={categories}
                      value={formik.values?.categoryName}
                      isSearchable={false}
                      styles={selectDropdownStyle}
                      onChange={(selectedOption) => {
                        formik.setFieldValue("categoryName", selectedOption);
                        setSubCategories(selectedOption.subCategories);
                        formik.setFieldValue("subCategoryName", "");
                      }}
                      filterOption={(option) => option.data.isParentCategory}
                    />
                    {formik.touched?.categoryName &&
                      formik.errors.categoryName && (
                        <span className="error-message input-group-alternative">
                          {formik.errors.categoryName}
                        </span>
                      )}
                  </section>
                </Col>
                <Col md={4}>
                  <Label for="exampleSelect" className="inputfield">
                    Sub Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select
                      className="custom-select"
                      isDisabled={!subCategories?.length}
                      value={formik.values?.subCategoryName}
                      options={subCategories}
                      isSearchable={false}
                      styles={selectDropdownStyle}
                      onChange={(selectedOption) => {
                        formik.setFieldValue("subCategoryName", selectedOption);
                      }}
                    />
                    {formik.touched?.subCategoryName &&
                      formik.errors.subCategoryName && (
                        <span className="error-message input-group-alternative">
                          {formik.errors.subCategoryName}
                        </span>
                      )}
                  </section>
                </Col>
                <Col md={4}>
                  <InputField
                    required
                    placeholder="Price"
                    fieldName="price"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label
                    for="exampleSelect"
                    className="inputfield required-field"
                  >
                    Recommended
                  </Label>
                  <InputGroup className="recommended-field">
                    <label className="custom-toggle">
                      <Input
                        type="checkbox"
                        className="form-control"
                        name="recommended"
                        checked={formik.values?.recommended}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color="outline-primary"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          disabled={!!Object.values(formik.errors)?.length}
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export function categoriesListDTO(data) {
  let categories = [];

  if (data?.length) {
    categories = data.map((cat) => ({
      id: cat?.id || 0,
      name: cat?.name || null,
      isParentCategory: cat?.isParentCategory,
      parentCategory: cat?.parentCategory ? {
        id: cat?.parentCategory?.id || 0,
        name: cat?.parentCategory?.name || null,
        isParentCategory: cat?.parentCategory?.isParentCategory,
      } : {},
      subCategories: !!cat?.subCategories?.length ? cat?.subCategories.map(sub => ({
        id: sub?.id || 0,
        name: sub?.name || null,
        isParentCategory: sub?.isParentCategory,
      })) : [],
      restaurantId: cat?.restaurantId || 0,
    }))
  }

  return categories;
}
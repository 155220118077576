// Base URL
const BASE_URL = process.env.REACT_APP_BASE_URL;

// ******  Admin ****** //
export const LOGIN_API = `${BASE_URL}/dev/login`;
export const FORGOT_PASSWORD_API = `${BASE_URL}/dev/forgot-password`;
export const RESET_PASSWORD_API = `${BASE_URL}/dev/reset-password`;
export const EMAIL_VERIFICATION_API = `${BASE_URL}/dev/email-verification`;
export const TABLES_API = `${BASE_URL}/dev/tables`;
export const CATEGORIES_API = `${BASE_URL}/dev/categories`;
export const CUSTOMERS_API = `${BASE_URL}/dev/customers`;
export const TAGS_API = `${BASE_URL}/dev/customer-group-tag`;
export const PRODUCTS_API = `${BASE_URL}/dev/products`;
export const CAMPAIGNS_API = `${BASE_URL}/dev/campaigns`;
export const ORDERS_API = `${BASE_URL}/dev/orders`;
export const COUPONS_API = `${BASE_URL}/dev/coupons`;
export const REPORTS_API = `${BASE_URL}/dev/reports`;
export const SUBSCRIPTION_PAYMENT_API = `${BASE_URL}/dev/subscriptions`;
export const RESERVATIONS_API = `${BASE_URL}/dev/reservations`;
export const FEEDBACKS_API = `${BASE_URL}/dev/feedbacks`;
export const RESTAURANT_DASHBOARD_API = `${BASE_URL}/dev/restaurant/dashboard`;
export const RESTAURANT_SETTING_API = `${BASE_URL}/dev/restaurants/settings`;
export const RESTAURANT_STRIPE_API = `${BASE_URL}/dev/restaurants`;
export const TEMPLATES_API = `${BASE_URL}/dev/templates`;
export const SUPER_ADMIN_LOGIN_API = `${BASE_URL}/dev/super-admin-login`;

// ******  Super Admin ****** //
export const RESTAURANT_API = `${BASE_URL}/dev/restaurants`;
export const SETTINGS_API = `${BASE_URL}/dev/admin/settings`;
export const SUPER_ADMIN_DASHBOARD_API = `${BASE_URL}/dev/admin/dashboard`;
export const SUBSCRIPTIONS_API = `${BASE_URL}/dev/subscriptions`;
export const MESSAGES_API = `${BASE_URL}/dev/admin/messages`;

// ******  Common ****** //
export const CHANGE_PASSWORD_API = `${BASE_URL}/dev/change-password`;
export const UPLOAD_IMAGE_API = `${BASE_URL}/dev/upload-image`;

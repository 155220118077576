import { CATEGORIES_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { categoriesListDTO } from "../dtos/categories"

export const getCategories = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, CATEGORIES_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminCategories.SetCategories, categoriesListDTO(res.data.data.rows))
      dispatch(Actions.RestaurantAdminCategories.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addCategories = (data, search, activeSort, sortOrder, page, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, CATEGORIES_API, data)
    .then((res) => {
      handleSuccess(res)
      handleCloseAddModal()
      getCategories(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editCategories = (id, data, search, activeSort, sortOrder, page, handleCloseEditModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${CATEGORIES_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()
      getCategories(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteCategory = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${CATEGORIES_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      getCategories(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}
export default function superAdmin(
  initialState = {
    loading: false,
    settings: { currencySign: '' },
    messages: []
  },
  action
) {
  switch (action.type) {
    case 'SET_SUPER_ADMIN_LOADING':
      return {
        ...initialState,
        loading: action.payload,
      };

    case 'SET_SUPER_ADMIN_SETTINGS_FIELDS':
      return {
        ...initialState,
        settings: action.payload,
      };

    case 'SET_SUPER_ADMIN_SETTINGS_FIELDS_MESSAGES':
      return {
        ...initialState,
        messages: action.payload,
      };

    case 'SUPER_ADMIN_RESET':
      return {
        ...initialState,
        loading: false,
        settings: { currencySign: '' },
        messages: []
      };

    default:
      return initialState;
  }
}

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, Row } from "reactstrap";
import { formatBytes } from '../../../../services/middleware';
import { EXCEL_FILE_TYPE, ImportCustomerNote, MaximumFileSize, MinimumFileSize } from '../../../../constants/General';

export default function ImportCustomersModal(props) {
  // Props
  const { open, handleCloseModal, handleSuccess } = props

  const formik = useFormik({
    initialValues: {
      customers: '',
    },
    validationSchema: Yup.object({
      customers: Yup.mixed()
        .test('FILE_FORMAT', `You can only upload ${EXCEL_FILE_TYPE.join()} files.`, (value) => {
          console.log(value, "value?.type?.split('/')[1]?.toLocaleLowerCase()");
          return (!value || (value?.name && EXCEL_FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase())))
        }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
          return (!value || (value?.size && value.size <= MaximumFileSize))
        }),
    }),
    onSubmit: (values) => {
      var payload = new FormData();
      payload.append('file', values.customers)
      handleSuccess(payload)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Import Customer
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col >
                  <Label for="exampleSelect" className={`inputfield`}>
                    Customers
                  </Label>
                  <div className="space-between-div cursor-pointer">
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        className="custom-form-control mr-3"
                        id="customers"
                        type="file"
                        accept={['.csv']}
                        invalid={!!formik?.errors?.customers}
                        onChange={(e) => {
                          if (
                            e.currentTarget.files &&
                            e.currentTarget.files[0]
                          ) {
                            formik.setFieldValue("customers", e.currentTarget.files[0]);
                          }
                        }}
                      />
                      <FormFeedback>
                        {formik?.errors?.customers}
                      </FormFeedback>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              <span className="error-message input-group-alternative">{ImportCustomerNote}</span>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}

import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, CardBody, Form, Modal, Row, Col, Label } from "reactstrap";
import InputField from '../../../../components/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { addSubscriptionValidationSchema } from '../../../../constants/Schemas';
import { addSubscription } from '../../utils/middleware/subscriptions';
import { generateDropdownOptionFromObject, removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { selectDropdownStyle, subscriptionType } from '../../../../constants/General';
import FeatureList from '../../../../components/FeaturesList';

export default function AddSubscriptionModal(props) {
  // Props
  const { open, handleCloseModal } = props

  // Store
  const { search, activeSort, sortOrder, page } = useSelector(state => state.superAdminRestaurants)

  // State
  const [permissions, setPermissions] = useState({})

  const formik = useFormik({
    initialValues: {
      name: '',
      amount: '',
      type: '',
      description: '',
    },
    validationSchema: addSubscriptionValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      trimObjectValues(values)
      const filterData = removeEmptyKeysFromObject(values)
      addSubscription({ ...filterData, type: filterData.type?.value, feature: permissions }, search, activeSort, sortOrder, page, resetForm, handleCloseModal)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add Subscription
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody >
            <Form role="form">
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Amount" fieldName="amount" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    Type
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" value={formik.values.type} options={generateDropdownOptionFromObject(subscriptionType)} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("type", selectedOption);
                      }}
                    />
                    {formik.touched?.type && formik.errors.type && <span className="error-message input-group-alternative">{formik.errors.type}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <InputField placeholder="Description" fieldName="description" formik={formik} />
                </Col>
              </Row>
              <FeatureList permissions={permissions} setPermissions={setPermissions} />
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}

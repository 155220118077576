import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, Input, FormGroup, Label } from "reactstrap";
import { useFormik } from 'formik';
import { AES, enc } from 'crypto-js';
import { LOGIN_API } from "../../constants/Configs";
import InputField from "../../components/InputField";
import { Store } from '../../redux/configureStore';
import { Actions } from "../../redux/actions";
import { API_REQUEST, USER_ROLE } from "../../constants/General";
import { getRememberMeData, removeRememberMeData, setAccessToken, setLocalStorageUserData, setRememberMeData } from "../../utils/session";
import { useState } from "react";
import { handleError, handleSuccess } from "../../utils/toast";
import { loginValidationSchema } from "../../constants/Schemas";
import API from "../../services/api";
import Loader from "../../components/Loader";
import { trimObjectValues } from "../../services/middleware";
import logo from '../../assets/images/logo-white.svg';

const Login = () => {
  const navigate = useNavigate();
  const secretKey = process.env.REACT_APP_SECRET_KEY

  // State
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(!!getRememberMeData());
  const [loading, setLoading] = useState(false);
  const [email = "", password = ""] = !!getRememberMeData()?.wp_rememberme ? AES.decrypt(getRememberMeData().wp_rememberme, secretKey).toString(enc.Utf8).split("|") : []

  // Handle form values
  const formik = useFormik({
    initialValues: {
      email,
      password
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values)
      userLogin({
        email: values.email,
        password: values.password
      }, resetForm)
    }
  });

  // Login api call
  const userLogin = (userData, resetForm) => {
    setLoading(true)
    API(API_REQUEST.post, LOGIN_API, userData)
      .then((res) => {
        handleSuccess(res)
        setLocalStorageUserData(res?.data?.data?.userData);
        setAccessToken(res?.data?.data?.tokenData);
        rememberMe ? setRememberMeData({
          wp_rememberme: AES.encrypt(`${userData.email}|${userData.password}`, secretKey).toString(),
        }) : removeRememberMeData()
        resetForm({ values: '' });
        Store.dispatch({ type: Actions.User.SetIsAdminUser, payload: USER_ROLE.SUPER_ADMIN === res?.data?.data?.userData?.role })
        setLoading(false)
        navigate('/admin');
      })
      .catch((error) => {
        handleError(error)
        setLoading(false)
        console.log(error);
      })
  }

  const handleSubmitOnEnter = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit()
    }
  }

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span><img src={logo} alt="logo" /></span>
        </div>
        <Card className="shadow">
          {loading && <Loader />}
          <CardBody>
            <div className="mb-4">
              <h1>Log In</h1>
            </div>
            <Form role="form">
              <InputField placeholder="Email" fieldName="email" formik={formik} onKeyPress={handleSubmitOnEnter} />
              <InputField passwordField placeholder="Password" fieldName="password" onKeyPress={handleSubmitOnEnter} formik={formik} inputType={showPassword ? "text" : "password"} passwordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
              <FormGroup check className="custom-checkbox">
                <Label check>
                  <Input type="checkbox" checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} /> Remember me
                </Label>
              </FormGroup>
              <div className="btn-wrap">
                <Button color='primary' disabled={!!Object.values(formik.errors)?.length} type="button" onClick={formik.handleSubmit} >
                  Log in
                </Button>
              </div>
            </Form>
            <div className="links-wrap">
              <Link to="/forgotPassword" className="secondary-link">
                Forgot password?
              </Link>
              <Link to="/register" className="secondary-link">
                Create new account
              </Link>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;

export default function coupons(
  initialState = {
    coupons: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_COUPONS':
      return {
        ...initialState,
        coupons: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_COUPONS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_COUPONS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_COUPONS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_COUPONS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_COUPONS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };


    case 'RESTAURANT_ADMIN_COUPONS_RESET':
      return {
        ...initialState,
        coupons: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}

import React from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Col, Form, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import { tagValidationSchema } from '../../../../constants/Schemas';
import { trimObjectValues } from '../../../../services/middleware';
import { selectDropdownStyle } from '../../../../constants/General';
import { useSelector } from 'react-redux';

export default function TagModal(props) {
  // Props
  const { open, tag, handleCloseModal, handleSuccess } = props

  // Store
  const { customers } = useSelector(state => state.restaurantAdminTags)

  const formik = useFormik({
    initialValues: {
      tagName: tag?.tagName || '',
      customers: !!tag?.customers?.length ? tag?.customers.map(customer => ({ label: customer?.name || '', value: customer?.id || '' })) : []
    },
    validationSchema: tagValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      values.customers = values.customers.map(customer => customer.value)
      handleSuccess(values)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Tag
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <InputField required placeholder="Tag Name" fieldName="tagName" formik={formik} />
              <Row>
                <Col>
                  <Label for="exampleSelect" className='inputfield required-field'>
                    Customers
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isMulti className="custom-select" options={customers} value={formik.values?.customers} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("customers", selectedOption);
                      }}
                    />
                    {formik.errors.customers && <span className="error-message input-group-alternative">{formik.errors.customers}</span>}
                  </section>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}

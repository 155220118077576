import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Col, Spinner, Media } from "reactstrap";
import API from "../../services/api";
import { API_REQUEST, OrderStatus } from "../../constants/General";
import { ORDERS_API } from "../../constants/Configs";
import { handleError } from "../../utils/toast";
import { useLocation } from "react-router-dom";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { redirectToWhatsapp } from "../../services/middleware";

export default function Checkout() {
  const location = useLocation();
  const pathName = new URLSearchParams(location?.search);

  // State
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  // Mount
  useEffect(() => getOrderData(pathName.get("orderId")), []); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrderData = (id) => {
    setLoading(true);
    API(API_REQUEST.get, `${ORDERS_API}/${id}/order-data`)
      .then((res) => {
        setLoading(false);
        setOrderData(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        redirectToWhatsapp(pathName.get("phone"));
        handleError(error);
      });
  };

  const openCheckoutPage = (id, phone) => {
    const params = {
      ...(!!phone ? { phone } : {}),
    };
    setLoading(true);
    API(API_REQUEST.get, `${ORDERS_API}/payments/${id}`, { params })
      .then((res) => {
        setLoading(false);
        if (res?.data?.data) {
          window.open(res.data.data, "_blank");
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  return (
    <div className="center-div">
      <Col md="7">
        <Card className="bg-secondary shadow">
          <CardBody>
            <Form role="form">
              {loading ? (
                <div className="center-div email-verification-loader">
                  <Spinner
                    className="loader-color"
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                </div>
              ) : orderData ? (
                <>
                  <Media className="flex-direction-column align-items-center mb-3">
                    <span
                      className={`avatar avatar-logo rounded-circle relative mb-2 avatar-xlg`}
                    >
                      {orderData?.restaurant?.logo ? (
                        <img alt="..." src={orderData?.restaurant?.logo} />
                      ) : (
                        <FontAwesomeIcon icon={faImage} size="3x" />
                      )}
                    </span>
                    <section>{orderData?.restaurant?.name}</section>
                  </Media>
                  <div className="space-between-div mb-2">
                    <h5>
                      {orderData?.customers?.length !== 0
                        ? `Customers : ${orderData?.customers?.join(", ")}`
                        : "No customer available."}
                    </h5>
                    <table className="invoice table-bordered">
                      <tbody>
                        <tr>
                          <td>Bill No:</td>
                          <td>{orderData?.billNo}</td>
                        </tr>
                        <tr>
                          <td>Date:</td>
                          <td>{orderData?.date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Card>
                    <CardBody className="p-0">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          {orderData?.items?.length > 0 ? (
                            <table className="table products table-bordered">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderData?.items?.map((item, i) => (
                                  <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td>
                                      {item.quantity * item.price &&
                                        `${orderData?.currencySign}${
                                          item.quantity * item.price
                                        }`}
                                    </td>
                                  </tr>
                                ))}
                                <tr className="text-right">
                                  <td colSpan="3">Subtotal:</td>
                                  <td>
                                    {orderData?.subTotal &&
                                      `${orderData?.currencySign}${orderData.subTotal}`}
                                  </td>
                                </tr>
                                <tr className="text-right">
                                  <td colSpan="3">VAT:</td>
                                  <td>{orderData?.vat}%</td>
                                </tr>
                                <tr className="text-right">
                                  <td colSpan="3">Discount:</td>
                                  <td>
                                    {orderData?.totalDiscount &&
                                      `${orderData?.currencySign}${orderData.totalDiscount}`}
                                  </td>
                                </tr>
                                <tr className="text-right">
                                  <td colSpan="3">Coupon Discount:</td>
                                  <td>
                                    {orderData?.couponDiscount &&
                                      `${orderData?.currencySign}${orderData.couponDiscount}`}
                                  </td>
                                </tr>
                                <tr className="text-right">
                                  <td colSpan="3">Coin's Discount:</td>
                                  <td>
                                    {orderData?.coinsDiscount &&
                                      `${orderData?.currencySign}${orderData.coinsDiscount}`}
                                  </td>
                                </tr>
                                <tr className="text-right">
                                  <td colSpan="3">Total:</td>
                                  <td>
                                    {orderData?.total &&
                                      `${orderData?.currencySign}${orderData.total}`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <p>No items available.</p>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="text-right">
                    <Button
                      color="primary"
                      className="mt-4"
                      disabled={
                        orderData?.status === OrderStatus.COMPLETE ||
                        orderData.status === OrderStatus["PAYMENT DONE"]
                      }
                      type="button"
                      onClick={() =>
                        openCheckoutPage(
                          pathName.get("orderId"),
                          pathName.get("phone")
                        )
                      }
                    >
                      Pay Now
                    </Button>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  It seems like there was an error. Please try again.
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

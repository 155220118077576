import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Card, CardHeader, Table, Row, Button, Collapse, CardBody, UncontrolledTooltip, Badge, } from "reactstrap";
import { completeOrder, getCustomers, getOrders, getProducts, getTables, orderStatus } from "../../utils/middleware/orders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight, faLink, faListCheck, faPen, faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import { handleSortColumn, sortIcon } from "../../../../services/middleware";
import ConfirmationModal from '../../../../components/ConfirmationModal';
import NoDataLabel from "../../../../components/NoDataLabel";
import CustomPagination from "../../../../components/Pagination";
import AddOrderModal from "../../components/AddOrderModal";
import { OrderStatus, SubscriptionPaymentStatus, orderTooltipText } from "../../../../constants/General";
import EditOrderModal from "../../components/EditOrderModal";
import SendPaymentLink from "../../components/SendPaymentLink";
import SearchInput from "../../../../components/SearchInput";
import DateRangeSelector from "../../components/DateRangeSelector";

const Orders = () => {
  // Store
  const { orders, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminOrders)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false)
  const [sendPaymentLinkModal, setSendPaymentLink] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [openOrder, setOpenOrder] = useState();
  const [productsByCategory, setProductsByCategory] = useState([])
  const [dateRangeSelector, setDateRangeSelector] = useState(false)

  // Mount
  useEffect(() => {
    getOrders(search, startDate, endDate, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminOrders.SetPage, pageCount)
    }
  }, [search, startDate, endDate, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedOrder(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedOrder(null)
  }

  const handleCloseCompleteOrderModal = () => {
    setShowCompleteOrderModal(false)
    setSelectedOrder(null)
  }

  const handleCloseSendPaymentLinkModal = () => {
    setSendPaymentLink(false)
    setSelectedOrder(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminOrders.Reset), [])

  return (
    <div>
      {showAddModal ? <AddOrderModal productsByCategory={productsByCategory} handleCloseAddModal={handleCloseAddModal} /> :
        showEditModal ? <EditOrderModal productsByCategory={productsByCategory} order={selectedOrder} handleCloseEditModal={handleCloseEditModal} /> : <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 space-between-div table-header-div">
                  <h3 className="mb-0">Orders List</h3>
                  <div className="right-div-wrap">
                    <SearchInput action="RestaurantAdminOrders" />
                    <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
                      {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                    </Button>
                    <Button color='primary' onClick={async () => {
                      getCustomers()
                      getTables()
                      await getProducts().then(products => {
                        if (products) {
                          setProductsByCategory(products)
                          setShowAddModal(true)
                        }
                      })
                    }}>
                      Add
                    </Button>
                  </div>
                </CardHeader>
                {orders?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'orders')}>No.</th> */}
                      <th scope='col' className={`${activeSort === 'orderId' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('orderId', activeSort, sortOrder, 'orders')}>Order ID <FontAwesomeIcon icon={sortIcon(activeSort, 'orderId', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'createdAt' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('createdAt', activeSort, sortOrder, 'orders')}>Order Date <FontAwesomeIcon icon={sortIcon(activeSort, 'createdAt', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'tableNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('tableNumber', activeSort, sortOrder, 'orders')}>Table Number <FontAwesomeIcon icon={sortIcon(activeSort, 'tableNumber', sortOrder)} /></th>
                      <th scope='col'>Items </th>
                      <th scope='col' className={`${activeSort === 'status' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('status', activeSort, sortOrder, 'orders')}>Status <FontAwesomeIcon icon={sortIcon(activeSort, 'status', sortOrder)} /></th>
                      <th scope='col'>Customers</th>
                      <th scope='col' className={`${activeSort === 'totalDiscountValue' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('totalDiscountValue', activeSort, sortOrder, 'orders')}>Discount <FontAwesomeIcon icon={sortIcon(activeSort, 'totalDiscountValue', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'total' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('total', activeSort, sortOrder, 'orders')}>Total <FontAwesomeIcon icon={sortIcon(activeSort, 'total', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'rating' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('rating', activeSort, sortOrder, 'orders')}>Feedback <FontAwesomeIcon icon={sortIcon(activeSort, 'rating', sortOrder)} /></th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr className="cursor-pointer" onClick={() => setOpenOrder(openOrder === order?.id ? '' : order?.id)}>
                            {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                            <td>{order?.orderId}</td>
                            <td>{order?.createdAt ? moment(order?.createdAt).format('DD-MM-YYYY') : ''}</td>
                            <td>{order?.tableNumber}</td>
                            <td>{order?.items?.length}</td>
                            <td>{orderStatus(order)}</td>
                            <td>{order.customers?.length}</td>
                            <td>{order.totalDiscountValue}{order?.discount?.length !== 0 ? `(${order?.discount.map((item) => item?.type === 'percentage' ? `${item?.discountValue}%` : `${settings?.superAdminSetting?.currencySign}${item?.discountValue}`).join("+")})` : ''}</td>
                            <td>{order.total && `${settings?.superAdminSetting?.currencySign}${order.total}`}</td>
                            <td id={`feedback-${index}`}>{Array.from({ length: 5 }, (_, i) => i + 1).map((rate) => {
                              return (
                                <label key={rate}>
                                  <FontAwesomeIcon
                                    icon={rate <= order?.rating ? solidStar : regularStar}
                                    className="star"
                                    color="#ffc107"
                                    size='xl'
                                  />
                                </label>
                              );
                            })}</td>
                            <td className="text-right">
                              <Button color="info" disabled={order.status === OrderStatus.COMPLETE} className="action-icon-btn" id={`sendPaymentLink-${index}`} onClick={(e) => {
                                e.stopPropagation()
                                setSelectedOrder(order)
                                setSendPaymentLink(true)
                              }}>
                                <FontAwesomeIcon icon={faLink} />
                              </Button>
                              {order.status !== OrderStatus.COMPLETE && <Button color="warning" className="action-icon-btn" id={`completeOrder-${index}`} onClick={(e) => {
                                e.stopPropagation()
                                setSelectedOrder(order)
                                setShowCompleteOrderModal(true)
                              }}>
                                <FontAwesomeIcon icon={faListCheck} />
                              </Button>}
                              {!(order.status === OrderStatus["PAYMENT DONE"] ||
                                order.status === OrderStatus["PAYMENT INITIATED"] ||
                                order.status === OrderStatus.CANCELLED ||
                                order.status === OrderStatus.COMPLETE) && <Button color="success" className="action-icon-btn" id={`edit-${index}`} onClick={async (e) => {
                                  e.stopPropagation()
                                  getCustomers()
                                  getTables()
                                  setSelectedOrder(order)
                                  await getProducts().then(products => {
                                    if (products) {
                                      setProductsByCategory(products)
                                      setShowEditModal(true)
                                    }
                                  })
                                }}>
                                  <FontAwesomeIcon icon={faPen} />
                                </Button>}
                              <Button className="action-icon-btn">
                                <FontAwesomeIcon className="ml-1" icon={openOrder === order.id ? faChevronDown : faChevronRight} />
                              </Button>

                              {orderTooltipText.map((tooltip) => {
                                return ((tooltip.target === 'completeOrder' && order.status !== OrderStatus.COMPLETE) || (tooltip.target === 'sendPaymentLink') || (tooltip.target === 'edit' && !(order.status === OrderStatus["PAYMENT DONE"] ||
                                  order.status === OrderStatus["PAYMENT INITIATED"] ||
                                  order.status === OrderStatus.CANCELLED ||
                                  order.status === OrderStatus.COMPLETE))) && <UncontrolledTooltip key={`${tooltip.target}-${index}`}
                                    delay={tooltip.delay}
                                    placement={tooltip.placement}
                                    target={`${tooltip.target}-${index}`}
                                  >
                                    {tooltip.text}
                                  </UncontrolledTooltip>
                              })}
                              <UncontrolledTooltip
                                delay={0}
                                placement="top"
                                target={`feedback-${index}`}
                              >
                                {order?.feedbackMessage || 'Feedback'}
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                          <tr key={order.id + "-details"} className={openOrder === order?.id ? '' : 'd-none'}>
                            <td colSpan="11">
                              <Collapse isOpen={openOrder === order?.id}>
                                <Card>
                                  <CardBody>
                                    <div className="d-flex">
                                      <div className="flex-grow-1 mr-4">
                                        <h5>Order Items:</h5>
                                        {order?.items?.length > 0 ? (
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {order?.items?.map((item, i) => (
                                                <tr key={i}>
                                                  <td>{item.name}</td>
                                                  <td>{item.quantity}</td>
                                                  <td>{settings?.superAdminSetting?.currencySign}{item.price}</td>
                                                  <td>{settings?.superAdminSetting?.currencySign}{item.price * item.quantity}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <p>No order items available</p>
                                        )}
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5>Customers:</h5>
                                        {order?.customers?.length > 0 ? (
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Number</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {order?.customers?.map((customer, i) => (
                                                <tr key={i}>
                                                  <td>{customer.name}</td>
                                                  <td>{customer.phone}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <p>No customers available</p>
                                        )}
                                        <h5 className="mt-2">Transactions:</h5>
                                        {order?.transactions?.length > 0 ? (
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Number</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {order?.transactions?.map((transaction, i) => (
                                                <tr key={i}>
                                                  <td>{transaction.phone}</td>
                                                  <td>
                                                    <Badge className={transaction.status === SubscriptionPaymentStatus.DONE ? 'badge-success' : transaction.status === SubscriptionPaymentStatus.PENDING ? 'badge-info' : 'badge-danger'}>
                                                      {transaction.status === SubscriptionPaymentStatus.DONE ? 'Done' : transaction.status === SubscriptionPaymentStatus.PENDING ? 'Pending' : 'Failed'}
                                                    </Badge>
                                                  </td>
                                                  <td>{transaction.amount}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <p>No transactions available</p>
                                        )}
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table> : <NoDataLabel />}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminOrders.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>
      }

      {showCompleteOrderModal && <ConfirmationModal open={showCompleteOrderModal} handleCloseModal={handleCloseCompleteOrderModal} handleSuccess={() => completeOrder({ id: selectedOrder?.orderId, search, startDate, endDate, activeSort, sortOrder, page, handleCloseCompleteOrderModal })} />}
      {sendPaymentLinkModal && <SendPaymentLink open={sendPaymentLinkModal} order={selectedOrder} handleCloseModal={handleCloseSendPaymentLinkModal} />}
      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        setStartDate={(date) => dispatch(Actions.RestaurantAdminOrders.SetStartDate, date)}
        setEndDate={(date) => dispatch(Actions.RestaurantAdminOrders.SetEndDate, date)}
      />}
    </div >
  )
};

export default Orders;

import React from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { setSelectedDropdownForSameValue, trimObjectValues, } from "../../../../services/middleware";
import { templateValidationSchema } from "../../../../constants/Schemas";
import { CountryCode, CountryCodeDropdownOptions, selectDropdownStyle, } from "../../../../constants/General";

export default function TemplateModal(props) {
  // Props
  const { open, template, handleCloseModal, handleSuccess } = props;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: template?.name || "",
      headerType: template?.headerType || "",
      body: template?.body || "",
      footer: template?.footer || "",
      buttons: !!template?.buttons?.length
        ? template?.buttons.map((item) => item.type)
        : [],
      countryCode: setSelectedDropdownForSameValue(
        template?.buttons
          ?.find((item) => item.type === "PHONE_NUMBER")
          ?.phone_number?.slice(0, 2) || CountryCode.UK
      ),
      PHONE_NUMBER: {
        text:
          template?.buttons?.find((item) => item.type === "PHONE_NUMBER")
            ?.text || "",
        phone_number:
          template?.buttons
            ?.find((item) => item.type === "PHONE_NUMBER")
            ?.phone_number?.substring(2) || "",
      },
      URL: {
        text:
          template?.buttons?.find((item) => item.type === "URL")?.text || "",
        url: template?.buttons?.find((item) => item.type === "URL")?.url || "",
      },
      QUICK_REPLY: {
        text:
          template?.buttons?.find((item) => item.type === "QUICK_REPLY")
            ?.text || "",
      },
    },
    validationSchema: templateValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values);
      const buttons = values.buttons.map((option) => ({
        type: option,
        ...values[option],
        ...(option === "PHONE_NUMBER" && {
          ...values[option],
          phone_number: `${values.countryCode?.value}${values[option].phone_number}`,
        }),
      }));
      const payload = {
        name: values.name,
        headerType: values.headerType,
        body: values.body,
        footer: values.footer,
        buttons: buttons,
      };
      handleSuccess(payload);
    },
  });

  const handleOptionChange = (e) => {
    const value = e.target.value;
    if (formik.values.buttons.includes(value)) {
      formik.setFieldValue(
        "buttons",
        formik.values.buttons.filter((option) => option !== value)
      );
    } else {
      formik.setFieldValue("buttons", [...formik.values.buttons, value]);
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size="lg"
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Template
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </button>
      </div>
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <InputField
                required
                placeholder="Name"
                fieldName="name"
                formik={formik}
              />
              <Row>
                <Col>
                  <Label
                    for="exampleSelect"
                    className="inputfield required-field"
                  >
                    Header
                  </Label>
                  <FormGroup tag="fieldset" inline disabled={!!template}>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="DOCUMENT"
                          checked={formik.values.headerType === "DOCUMENT"}
                          onChange={formik.handleChange}
                        />
                        Document
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="IMAGE"
                          checked={formik.values.headerType === "IMAGE"}
                          onChange={formik.handleChange}
                        />
                        Image
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="TEXT"
                          checked={formik.values.headerType === "TEXT"}
                          onChange={formik.handleChange}
                        />
                        Text
                      </Label>
                    </FormGroup>
                    {formik.touched?.headerType && formik.errors.headerType && (
                      <span className="error-message input-group-alternative">
                        {formik.errors.headerType}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <InputField
                required
                placeholder="Body"
                fieldName="body"
                inputType="textarea"
                rows={5}
                formik={formik}
              />
              <InputField
                required
                placeholder="Footer"
                fieldName="footer"
                formik={formik}
              />
              <Row>
                <Col>
                  <Label for="exampleSelect" className="inputfield">
                    Buttons
                  </Label>
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          value="QUICK_REPLY"
                          checked={formik.values.buttons.includes(
                            "QUICK_REPLY"
                          )}
                          onChange={handleOptionChange}
                        />
                        Quick Reply
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter text"
                        className="mb-2 mt-1"
                        value={formik.values.QUICK_REPLY.text}
                        disabled={
                          formik.values.buttons.indexOf("QUICK_REPLY") === -1
                        }
                        onChange={(e) =>
                          formik.setFieldValue("QUICK_REPLY", {
                            ...formik.values.QUICK_REPLY,
                            text: e.target.value,
                          })
                        }
                        invalid={formik.errors.QUICK_REPLY?.text}
                      />
                      <FormFeedback>
                        {formik.errors?.QUICK_REPLY?.text}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          value="URL"
                          checked={formik.values.buttons.includes("URL")}
                          onChange={handleOptionChange}
                        />
                        Visit Website
                      </Label>
                      <Row>
                        <Col md={5}>
                          <Label for="exampleSelect" className="inputfield">
                            Button Label
                          </Label>
                          <Input
                            type="text"
                            className="mb-2 mt-1"
                            placeholder="Enter label"
                            value={formik.values.URL.text}
                            disabled={
                              formik.values.buttons.indexOf("URL") === -1
                            }
                            onChange={(e) =>
                              formik.setFieldValue("URL", {
                                ...formik.values.URL,
                                text: e.target.value,
                              })
                            }
                            invalid={formik.errors.URL?.text}
                          />
                          <FormFeedback>
                            {formik.errors?.URL?.text}
                          </FormFeedback>
                        </Col>
                        <Col md={7}>
                          <Label for="exampleSelect" className="inputfield">
                            Button Value
                          </Label>
                          <Input
                            type="text"
                            className="mb-2 mt-1"
                            placeholder="Enter value"
                            value={formik.values.URL.url}
                            disabled={
                              formik.values.buttons.indexOf("URL") === -1
                            }
                            onChange={(e) =>
                              formik.setFieldValue("URL", {
                                ...formik.values.URL,
                                url: e.target.value,
                              })
                            }
                            invalid={formik.errors.URL?.url}
                          />
                          <FormFeedback>{formik.errors?.URL?.url}</FormFeedback>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          value="PHONE_NUMBER"
                          checked={formik.values.buttons.includes(
                            "PHONE_NUMBER"
                          )}
                          onChange={handleOptionChange}
                        />
                        Call Phone Number
                      </Label>
                      <Row>
                        <Col md={5}>
                          <Label for="exampleSelect" className="inputfield">
                            Button Label
                          </Label>
                          <Input
                            type="text"
                            placeholder="Enter label"
                            className="mb-2 mt-1"
                            value={formik.values.PHONE_NUMBER.text}
                            disabled={
                              formik.values.buttons.indexOf("PHONE_NUMBER") ===
                              -1
                            }
                            onChange={(e) =>
                              formik.setFieldValue("PHONE_NUMBER", {
                                ...formik.values.PHONE_NUMBER,
                                text: e.target.value,
                              })
                            }
                            invalid={formik.errors.PHONE_NUMBER?.text}
                          />
                          <FormFeedback>
                            {formik.errors?.PHONE_NUMBER?.text}
                          </FormFeedback>
                        </Col>
                        <Col md={7}>
                          <Label for="exampleSelect" className="inputfield">
                            Button Value
                          </Label>
                          <div className="d-flex">
                            <section className="mb-2 mt-1 input-group-alternative country-code">
                              <Select
                                isDisabled={
                                  formik.values.buttons.indexOf(
                                    "PHONE_NUMBER"
                                  ) === -1
                                }
                                className="custom-select"
                                options={CountryCodeDropdownOptions}
                                value={formik.values.countryCode}
                                isSearchable={false}
                                styles={selectDropdownStyle}
                                onChange={(selectedOption) => {
                                  formik.setFieldValue(
                                    "countryCode",
                                    selectedOption
                                  );
                                }}
                              />
                              {formik.touched?.countryCode &&
                                formik.errors.countryCode && (
                                  <span className="error-message input-group-alternative">
                                    {formik.errors.countryCode}
                                  </span>
                                )}
                            </section>
                            <div className="full-width">
                              <Input
                                type="text"
                                placeholder="Enter number"
                                className="mb-2 mt-1"
                                value={formik.values.PHONE_NUMBER.phone_number}
                                disabled={
                                  formik.values.buttons.indexOf(
                                    "PHONE_NUMBER"
                                  ) === -1
                                }
                                onChange={(e) =>
                                  formik.setFieldValue("PHONE_NUMBER", {
                                    ...formik.values.PHONE_NUMBER,
                                    phone_number: e.target.value,
                                  })
                                }
                                invalid={
                                  formik.errors.PHONE_NUMBER?.phone_number
                                }
                              />
                              <FormFeedback>
                                {formik.errors?.PHONE_NUMBER?.phone_number}
                              </FormFeedback>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color="outline-primary"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          disabled={!!Object.values(formik.errors)?.length}
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

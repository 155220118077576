import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import { categoryValidationSchema } from '../../../../constants/Schemas';
import { trimObjectValues } from '../../../../services/middleware';
import { selectDropdownStyle } from '../../../../constants/General';
import { getCategoriesForProducts } from '../../utils/middleware/products';
import { useSelector } from 'react-redux';

export default function CategoryModal(props) {
  // Props
  const { open, edit, category, isParentCategory, parentCategory, handleCloseModal, handleSuccess } = props

  // Store
  const { categories } = useSelector(state => state.restaurantAdminProducts)

  const formik = useFormik({
    initialValues: {
      name: category?.name || '',
      isParentCategory: isParentCategory || false,
      parentCategory: parentCategory || '',
    },
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      values.parentCategory = values.parentCategory?.value || '';
      if (values.isParentCategory) {
        delete values.parentCategory
      }
      handleSuccess(values)
    }
  });

  // Mount
  useEffect(() => {
    getCategoriesForProducts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Category
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <InputField required placeholder="Name" fieldName="name" formik={formik} />
              <Row>
                <Col md={6}>
                  <Label for="exampleSelect" className='inputfield'>
                    Is Parent Category
                  </Label>
                  <InputGroup className='recommended-field'>
                    <label className="custom-toggle">
                      <Input type="checkbox"
                        className="form-control"
                        name="isParentCategory"
                        defaultChecked={formik.values?.isParentCategory}
                        disabled={edit}
                        value={formik.values?.isParentCategory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </InputGroup>
                </Col>
                {!formik.values?.isParentCategory && <Col md={6}>
                  <Label for="exampleSelect" className='inputfield'>
                    Parent Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isDisabled={formik.values?.isParentCategory} className="custom-select" options={categories} value={formik.values?.parentCategory} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("parentCategory", selectedOption);
                      }}
                      filterOption={(option) => option.data.isParentCategory}
                    />
                    {formik.errors.parentCategory && <span className="error-message input-group-alternative">{formik.errors.parentCategory}</span>}
                  </section>
                </Col>}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}

import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { CHANGE_PASSWORD_API } from '../../constants/Configs';
import { API_REQUEST } from '../../constants/General';
import { changePasswordValidationSchema } from '../../constants/Schemas';
import { Actions } from '../../redux/actions';
import { dispatch } from '../../utils/store';
import { handleError, handleSuccess } from '../../utils/toast';
import { removeSessionData } from '../../utils/session';
import InputField from '../InputField';
import API from '../../services/api';

export default function ChangePassword(props) {
  // Props
  const { open, handleCloseModal } = props

  // State
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      changePassword(values, resetForm)
    }
  });

  const changePassword = (data, resetForm) => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    API(API_REQUEST.post, CHANGE_PASSWORD_API, data)
      .then((res) => {
        handleSuccess(res)
        removeSessionData()
        handleCloseModal()
        resetForm({ values: '' })
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch((err) => {
        handleError(err)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Change password
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <InputField passwordField placeholder="Old Password" fieldName="oldPassword" inputType={showOldPassword ? "text" : "password"} passwordIcon formik={formik} showPassword={showOldPassword} setShowPassword={setShowOldPassword} />
              <InputField passwordField placeholder="New Password" fieldName="newPassword" inputType={showNewPassword ? "text" : "password"} passwordIcon formik={formik} showPassword={showNewPassword} setShowPassword={setShowNewPassword} />
              <InputField passwordField placeholder="Confirm new password" fieldName="confirmNewPassword" inputType={showConfirmNewPassword ? "text" : "password"} passwordIcon formik={formik} showPassword={showConfirmNewPassword} setShowPassword={setShowConfirmNewPassword} />
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button type="button" color='primary' onClick={formik.handleSubmit}>
          Change
        </Button>
      </div>
    </Modal>
  )
}

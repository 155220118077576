import moment from "moment"
import { RESTAURANT_STRIPE_API, RESTAURANT_SETTING_API } from "../../../../constants/Configs"
import { API_REQUEST, reservationTimeInterval } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getLocalStorageUserData, setLocalStorageUserData } from "../../../../utils/session"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { restaurantSettingsDTO } from "../dtos/settings"

export const getSettings = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, RESTAURANT_SETTING_API)
    .then(res => {
      dispatch(Actions.RestaurantAdmin.SetSettings, restaurantSettingsDTO(res?.data?.data))
      setLocalStorageUserData({ ...getLocalStorageUserData(), isAppConfigured: restaurantSettingsDTO(res?.data?.data)?.isAppConfigured })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editSettings = (data, resetForm, setEditable) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, RESTAURANT_SETTING_API, data)
    .then(res => {
      resetForm({ values: '' })
      handleSuccess(res)
      getSettings()
      setEditable(false)
      setLocalStorageUserData({ ...getLocalStorageUserData(), isAppConfigured: true })
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

// Pass serviceHours in from and to format
export const initializeServiceHours = (serviceHours) => {
  let hours = {}
  if (serviceHours?.length) {
    serviceHours.forEach(week => {
      hours[week.day] = {}
      if (week.timeSlots?.length) {
        week.timeSlots.forEach((time, index) => {
          hours[week.day] = {
            ...hours[week.day],
            [`from${index + 1}`]: moment(time.from, 'HH:mm:ss'),
            [`to${index + 1}`]: moment(time.to, 'HH:mm:ss'),
          }
        })
      }
    })
  }
  return hours
}

export const setInitialValues = (restaurant) => {
  return {
    appId: restaurant?.appId,
    appName: restaurant?.appName,
    accessToken: restaurant?.accessToken,
    callbackUrl: restaurant?.callbackUrl,
    verifyToken: restaurant?.verifyToken,
    phoneNumberId: restaurant?.phoneNumberId,
    wabaId: restaurant?.wabaId,
    fbCatalogId: restaurant?.fbCatalogId,
    serviceHours: restaurant?.serviceHours,
    visitCount: restaurant?.visitCount,
    allowLoyalty: restaurant?.allowLoyalty,
    rewardCoinsPrice: restaurant?.rewardCoinsPrice,
    rewardCoinsPerOrder: restaurant?.rewardCoinsPerOrder,
    minimumOrderPrice: restaurant?.minimumOrderPrice,
    minimumRewardedCoins: restaurant?.minimumRewardedCoins,
    maxRedeemableCoins: restaurant?.maxRedeemableCoins,
    googleFeedbackUrl: restaurant?.googleFeedbackUrl,
    reservationTimeInterval: restaurant?.reservationTimeInterval ? reservationTimeInterval.find(time => time.value === restaurant.reservationTimeInterval) : '',
    welcomePDF: restaurant?.welcomePDF?.length ? restaurant?.welcomePDF.map(file => {
      const fileName = file.fileUrl.split('/').pop().replace('.pdf', '');
      return { fileName, fileUrl: file.fileUrl };
    }) : [{ fileName: '', fileUrl: '' }],
  }
}

export const createStripeAccount = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${RESTAURANT_STRIPE_API}/stripe-accounts`, {})
    .then(res => {
      handleSuccess(res)
      getSettings()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const linkBankAccounts = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, `${RESTAURANT_STRIPE_API}/stripe-account-links`)
    .then(res => {
      handleSuccess(res)
      getSettings()
      if (res?.data?.data) { window.open(res.data.data, "_blank") }
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const stripeAccountLogin = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, `${RESTAURANT_STRIPE_API}/stripe-account-login`)
    .then(res => {
      handleSuccess(res)
      getSettings()
      if (res?.data?.data) { window.open(res.data.data, "_blank") }
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}
import { faImage, faKey, faAngleDown, faQrcode, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Media } from "reactstrap";
import { getLocalStorageUserData, removeSessionData } from "../../utils/session";
import ChangePassword from "../ChangePassword";
import ViewQRCode from "../../pages/Restaurant/components/viewQRCode";
import { superAdminLogin } from "../../services/middleware";

const Header = (props) => {
  const navigate = useNavigate();

  // Store
  const { restaurant } = useSelector(state => state.restaurantAdmin)
  const isAdminUser = useSelector(state => state.user.isAdminUser)

  // State
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [viewQRModal, setViewQRModal] = useState(false)

  return (
    <div className="header-wrap">
      <Navbar className="navbar-top" expand="sm" id="navbar-main">
        <>
          <div className="left-block-wrap">
            <div className="h4 mb-0 text-uppercase" to="/">
              {props.brandText}
            </div>
          </div>
          <Nav className="align-items-center d-none d-lg-flex header-profile-menu" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav title={restaurant?.name || getLocalStorageUserData()?.name}>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {restaurant?.logo ? <img alt="Logo" src={restaurant?.logo} className="full-height" /> :
                      <FontAwesomeIcon icon={faImage} />}
                  </span>
                  <Media className="ml-2 d-none d-lg-inline-flex align-items-center">
                    <span className="text-sm profile-name">
                      {restaurant?.name || getLocalStorageUserData()?.name}
                    </span>
                    <i className="ml-2">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </i>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {!isAdminUser && <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>}
                <DropdownItem tag={Link} onClick={() => setChangePasswordModal(true)}>
                  <FontAwesomeIcon icon={faKey} />
                  <span>Change password</span>
                </DropdownItem>
                {!isAdminUser && <DropdownItem tag={Link} onClick={() => setViewQRModal(true)}>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span>Restaurant QR Code</span>
                </DropdownItem>}
                {!isAdminUser && getLocalStorageUserData()?.isSuperAdmin && <DropdownItem tag={Link} onClick={() => superAdminLogin(restaurant?.id, false, navigate)}>
                  <FontAwesomeIcon icon={faUsers} />
                  <span>Logged In as Super Admin</span>
                </DropdownItem>}
                <DropdownItem to="/admin/settings" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={() => removeSessionData()}>
                  <i className="ni ni-button-power" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </>
      </Navbar>

      {changePasswordModal && <ChangePassword open={changePasswordModal} handleCloseModal={() => setChangePasswordModal(false)} />}
      {viewQRModal && <ViewQRCode open={viewQRModal} restaurantName={getLocalStorageUserData()?.name} restaurantPhone={getLocalStorageUserData()?.phone} handleCloseModal={() => setViewQRModal(false)} />}
    </div>
  );
};

export default Header;

export function campaignsListDTO(data) {
  let campaigns = [];

  if (data?.length) {
    campaigns = data.map((campaign) => ({
      id: campaign?.id || 0,
      name: campaign?.name || null,
      campaignIssue: campaign?.campaignIssue ? {
        id: campaign?.campaignIssue?.id || 0,
        customerId: campaign?.campaignIssue?.customerId || "",
        campaignId: campaign?.campaignIssue?.campaignId || "",
        campaignSentDate: campaign?.campaignIssue?.campaignSentDate || "",
      } : null,
      message: campaign?.message || null,
      draftTemplateId: campaign?.draftTemplateId || null,
      media: campaign?.media || null,
      mediaType: campaign?.mediaType || null,
      tags: campaign?.tags?.length ? campaign?.tags?.map((tag) => ({
        id: tag?.id || 0,
        tagName: tag?.tagName || "",
      })) : [],
      customers: campaign?.customers?.length ? campaign?.customers?.map((customer) => ({
        id: customer?.id || 0,
        name: customer?.name || "",
        whatsappNumber: customer?.whatsappNumber || "",
      })) : [],
    }));
  }

  return campaigns;
}
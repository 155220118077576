export function restaurantsListDTO(data) {
  let restaurants = [];

  if (data?.length) {
    restaurants = data.map((restaurant) => ({
      id: restaurant?.id || null,
      name: restaurant?.name || null,
      tableCount: restaurant?.tableCount || 0,
      addressLine1: restaurant?.addressLine1 || null,
      addressLine2: restaurant?.addressLine2 || null,
      zipCode: restaurant?.zipCode || null,
      state: restaurant?.state || null,
      country: restaurant?.country || null,
      tagLine: restaurant?.tagLine || null,
      phone: restaurant?.phone?.slice(2) || null,
      email: restaurant?.email || null,
      logo: restaurant?.logo || null,
      latitude: restaurant?.latitude || null,
      longitude: restaurant?.longitude || null,
      status: restaurant?.status,
      serviceThrough: Array.isArray(restaurant?.serviceThrough) ? restaurant.serviceThrough : [],
      subscription: restaurant?.subscription ? {
        id: restaurant?.subscription?.id || 0,
        name: restaurant?.subscription?.name || null,
        type: restaurant?.subscription?.type || null,
        amount: restaurant?.subscription?.amount || null,
        description: restaurant?.subscription?.description || null,
        status: restaurant?.subscription?.status || null,
        startDate: restaurant?.subscription?.startDate || null,
        endDate: restaurant?.subscription?.endDate || null,
      } : null
    }))
  }

  return restaurants;
}
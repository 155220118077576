import { SUBSCRIPTIONS_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { subscriptionsListDTO } from "../dtos/subscriptions"

export const getSubscriptions = (search, sortBy, orderBy, page = 1, status) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  const params = {
    ...(!!status ? { status } : {}),
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, SUBSCRIPTIONS_API, { params })
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.SuperAdminSubscriptions.SetSubscriptions, subscriptionsListDTO(res?.data?.data?.rows))
      dispatch(Actions.SuperAdminSubscriptions.SetTotalCount, res?.data?.data?.count || 0)
      dispatch(Actions.SuperAdmin.SetLoading, false)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const addSubscription = (userData, search, activeSort, sortOrder, page, resetForm, handleCloseModal) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.post, SUBSCRIPTIONS_API, userData)
    .then((res) => {
      handleSuccess(res)
      handleCloseModal()
      resetForm({ values: '' });
      getSubscriptions(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const changeStatusSubscription = (id, search, activeSort, sortOrder, page, handleCloseModal) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.post, `${SUBSCRIPTIONS_API}/status/${id}`, {})
    .then(res => {
      handleSuccess(res)
      handleCloseModal()
      getSubscriptions(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}
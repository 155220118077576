import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import RestaurantSidebar from "../../../../components/RestaurantSidebar";
import Footer from "../../../../components/Footers";
import Header from "../../../../components/Navbars";
import { RestaurantRoutes } from "../../../../constants/Routes";
import Loader from "../../../../components/Loader";
import { getRestaurant } from "../../utils/middleware/restaurant";
import { getLocalStorageUserData } from "../../../../utils/session";
import { getSettings } from "../../utils/middleware/settings";

const Main = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.restaurantAdmin.loading);

  const getBrandText = () => {
    let route = RestaurantRoutes.find(
      (value) => location.pathname.indexOf(value.layout + value.path) !== -1
    );
    return route?.name || "Brand";
  };

  // Mount
  useEffect(() => {
    getRestaurant(getLocalStorageUserData()?.restaurantId);
    getSettings();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <RestaurantSidebar
        routes={RestaurantRoutes}
        logo={{
          innerLink: "/admin/dashboard",
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <header className="site-header">
          <Header brandText={getBrandText(location.pathname)} />
          {!getLocalStorageUserData()?.isAppConfigured && (
            <div className="setting-configure-error-container">
              <span className="setting-configure-error-text">
                Please configure your settings to access all feature. To
                configure :{" "}
              </span>
              <Button
                color="primary"
                className="ml-2"
                size="sm"
                onClick={() => navigate("/admin/settings")}
              >
                Click here
              </Button>
            </div>
          )}
        </header>
        <div
          className={`main-content-wrap ${!getLocalStorageUserData()?.isAppConfigured && "settings-error-main"
            }`}
        >
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Main;

export function customersListDTO(data) {
  let customers = [];

  if (data?.length) {
    customers = data.map((customer) => ({
      id: customer?.id || 0,
      name: customer?.name || null,
      rewardCoins: customer?.rewardCoins || null,
      whatsappNumber: customer?.whatsappNumber || null,
      isSubscribe: customer?.isSubscribe,
      about: customer?.about || null,
      whatsappId: customer?.whatsappId || null,
      tags: Array.isArray(customer?.tags) ? customer.tags : [],
    }))
  }

  return customers;
}
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody, Form, Spinner } from "reactstrap";
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import API from '../../services/api';
import { API_REQUEST, BrandName } from '../../constants/General';
import { handleError, handleSuccess } from '../../utils/toast';
import { FEEDBACKS_API } from '../../constants/Configs';
import InputField from '../../components/InputField';
import { useFormik } from 'formik';
import { feedbackValidationSchema } from '../../constants/Schemas';
import { redirectToWhatsapp, removeEmptyKeysFromObject, trimObjectValues } from '../../services/middleware';

export default function Feedback() {
  const location = useLocation();
  const pathName = new URLSearchParams(location?.search);

  // State
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(0);
  const editable = pathName.get("rating") === null;

  const formik = useFormik({
    initialValues: {
      value: pathName.get("rating") || 0,
      feedbackMessage: "",
    },
    validationSchema: feedbackValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values);
      const filterData = removeEmptyKeysFromObject(values);
      submitRating(filterData);
    },
  });

  const submitRating = (data) => {
    setLoading(true)
    API(API_REQUEST.post, `${FEEDBACKS_API}/${pathName.get('feedbackId')}`, data)
      .then((res) => {
        handleSuccess(res);
        redirectToWhatsapp(pathName.get("phone"));
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  };

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <div className="text-center mb-4">
              <h1>Customer Feedback</h1>
            </div>
            <Form role="form">
              {loading ? (
                <div className="center-div email-verification-loader">
                  <Spinner
                    className="loader-color"
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="text-center center-div mb-4">
                    We value your opinion and would love to hear your feedback
                    on your meal today. Could you take a moment to share your
                    thoughts with us?
                  </div>
                  <div className="center-div mb-2">
                    {Array.from({ length: 5 }, (_, i) => i + 1).map((rate) => {
                      return (
                        <label
                          key={rate}
                          className={`${editable && "cursor-pointer"}`}
                        >
                          <input
                            type="radio"
                            name="value"
                            value={formik.values.value}
                            checked={rate === formik.values.value}
                            onClick={() =>
                              editable && formik.setFieldValue("value", rate)
                            }
                            style={{ display: "none" }}
                          />
                          <FontAwesomeIcon
                            icon={
                              rate <= (hover || formik.values.value)
                                ? solidStar
                                : regularStar
                            }
                            className="star"
                            color="#ffc107"
                            size="xl"
                            onMouseEnter={() => editable && setHover(rate)}
                            onMouseLeave={() => editable && setHover(0)}
                          />
                        </label>
                      );
                    })}
                  </div>
                  <InputField
                    inputType="textarea"
                    rows={2}
                    placeholder="Feedback Message"
                    fieldName="feedbackMessage"
                    formik={formik}
                  />
                  <div className="btn-wrap">
                    <Button
                      color="primary"
                      disabled={!!Object.values(formik.errors)?.length}
                      type="button"
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

import React from "react";
import { Card, CardBody, Form } from "reactstrap";
import { BrandName } from "../../constants/General";

export default function PrivacyPolicy() {
  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap privacy-policy-div">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <Form role="form">
              <div className="text-center mb-4">
                <h1>WePOS- Privacy Policy</h1>
              </div>
              <section>
                <div>
                  <h3>Introduction</h3>
                  <p>
                    When you use our services, you’re trusting us with your
                    information. We understand this is a big responsibility and
                    work hard to protect your information and put you in
                    control.
                  </p>
                  <p>
                    This Privacy Policy is meant to help you understand what
                    information we collect, why we collect it, and what you can
                    do to protect your information.
                  </p>
                  <p>
                    Some key terms are defined as follows, and throughout this
                    document:
                    <ol>
                      <li>
                        RAG TECH LTD (“Wepos.ai”, “Wepos”, “RAG TECH”, “we”,
                        “us”, or “our”) is the company that collects and
                        processes Personal Data for the purposes described in
                        this Policy
                      </li>
                      <li>
                        Personal Data is any information relating to an
                        identified or identifiable natural person (“Data
                        Subject”)
                      </li>
                      <li>
                        Customer or Client is a legal business entity with whom
                        Wepos has an agreement to provide the Services
                      </li>
                    </ol>
                  </p>
                </div>
                <div>
                  <h3>What is Wepos</h3>
                  <p>
                    Wepos is a WhatsApp Business CRM tool that is powered by RAG
                    TECH LTD. The product provides a “Conversation Cloud
                    Platform” with WhatsApp Business that allows our Customers
                    to store, manipulate, analyze and transfer messages between
                    their business systems and their customers on a variety of
                    Wepos-provided and third-party messaging channels (the
                    “Service”).
                  </p>
                  <p>
                    Wepos is committed to the protection of Personal Data,
                    including data that we use for our own purposes, and that we
                    maintain on behalf of our Clients.
                  </p>
                  <p>
                    <h3>Collection and Use of Personal Data</h3>
                  </p>
                  Wepos collects information, including Personal Data, for the
                  following purposes:
                  <ul>
                    <li>Providing and managing the Service</li>
                    <li>Internal business purposes</li>
                    <li>Communicating with you and marketing</li>
                    <li>Recruiting and managing personnel</li>
                    <li>Collecting payment for the Service</li>
                    <li>To understand and improve our Service and Website</li>
                  </ul>
                  <p>
                    In order to provide our services to our clients we collect
                    the user’s name, email address and phone number and upload
                    these details into our secure servers
                    (<a href="https://app-server.Wepos.ai" target="_blank" rel="noreferrer">https://app-server.Wepos.ai</a>). These details are used only
                    to identify the users and deliver the content that they need
                    on the app. For a better experience, and in order to provide
                    our Service, we may require you to provide us, either
                    directly or indirectly, with certain personally identifiable
                    information, including but not limited to the user name, and
                    Oauth2 credentials. The information that we request will be
                    retained by us and used as described in this privacy policy.
                    The app uses third-party services that may collect
                    information used to identify you. Links to privacy policies
                    of third-party service providers used by Wepos:
                  </p>
                  <ul>
                    <li>
                      Zoho Service (<a href="https://www.zoho.com/en-uk/privacy.html" target="_blank" rel="noreferrer">https://www.zoho.com/en-uk/privacy.html</a>)
                    </li>
                    <li>
                      Facebook Sign-in Service
                      (<a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">https://www.facebook.com/policy.php</a>)
                    </li>
                    <li>Stripe Service (<a href="https://stripe.com/gb/privacy" target="_blank" rel="noreferrer">https://stripe.com/gb/privacy</a>)</li>
                    <li>Amazon aws (<a href="https://aws.amazon.com/legal" target="_blank" rel="noreferrer">https://aws.amazon.com/legal</a>)</li>
                  </ul>
                  <p>
                    This Policy is not intended to place any limits on what we
                    do with data that is aggregated and/or de-identified so it
                    is no longer associated with an identifiable individual
                    (Data Subject) or Customer of the Services. This Policy is
                    not intended to place any limits on what we do with data
                    that is aggregated and/or de-identified so it is no longer
                    associated with an identifiable individual (Data Subject) or
                    Customer of the Services.
                  </p>
                  <p>
                    Wepos Services are not directed to children under 16. If you
                    learn that a child under 16 has provided us with Personal
                    Data without consent, please contact us.
                  </p>
                </div>
                <div>
                  <h3>Cloud API</h3>
                  <p>
                    Wepos sends messages to Cloud API. The cloud API service
                    stores the messages temporarily. Then, it takes on the task
                    of sending the message to the WhatsApp platform. First,
                    messages are stored for any necessary transmissions. Then,
                    the WhatsApp messages are deleted automatically after 30
                    days.
                  </p>
                </div>
                <div>
                  <h3>Providing and managing the Service</h3>
                  <p>
                    In the course of providing the Service, Wepos may receive,
                    access, analyze, process and maintain Personal Data on
                    behalf of our Customers.
                  </p>
                  <p>
                    Our Customers determine the types of Personal Data that will
                    be collected and used within the Service, how it will be
                    used and disclosed, and how long it will be stored. For any
                    questions related to how your Personal Data is used by our
                    Customers, please contact them directly.
                  </p>
                  <p>
                    <b>Service Data,</b> is the information that is processed on
                    behalf of our Customers during the provision of the Service.
                    <ul>
                      <li>
                        Wepos’s privacy practices related to Service Data are
                        not covered by this Privacy Policy. They are detailed in
                        the Wepos Service Data Privacy Statement below
                      </li>
                      <li>
                        {" "}
                        This data privacy statement explains how and what
                        personal data we collect from you through our website.
                        Personal data means all data that can relate to you
                        personally, such as your name, address, e-mail
                        account(s), and user behaviour.
                      </li>
                      <li>
                        Your data is stored in secure cloud servers, and we try
                        our best to encrypt all personally identifiable
                        information
                      </li>
                      <li>
                        If you require us to delete your account data, you can
                        request so by sending us an email at support@Wepos.ai
                      </li>
                      <li>
                        Upon termination, all your data (contacts,
                        conversations, etc.) is deleted permanently from our
                        database unless you request otherwise.
                      </li>
                    </ul>
                  </p>

                  <p>
                    <b>Account Information,</b> including contact information,
                    user profile information, and information about your payment
                    method, is collected from you when you register or
                    authenticate into our Service and is used to manage payment
                    for the Service, enable us to provide support, and
                    facilitate communication.
                    <ul>
                      <li>
                        Wepos’s privacy practices
                        related to Account Information are detailed below and
                        throughout this Privacy Policy.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <b>Service Usage Information is collected,</b> including
                    information about how you are accessing and using the
                    Service. We use this information to understand and improve
                    our Services and to investigate and prevent security issues,
                    abuse, and fraud.
                    <ul>
                      <li>
                        Wepos’s privacy practices related to Service Usage
                        Information are detailed below and throughout this
                        Privacy Policy.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <b>Internal Business Purposes</b>
                    Wepos collects the following information from you through
                    our Website, social media, and other channels for the
                    following purposes:
                  </p>
                </div>
                <div>
                  <h2>Communicating with you and marketing practices</h2>
                  <p>
                    <b>Responding to your request for a product demo:</b>&nbsp;
                    When you request a free demo, we may collect your first and
                    last name, job title, WhatsApp number, business email
                    address, and information about your company. We use this
                    information to contact you and otherwise facilitate your
                    free demo
                  </p>
                  <p>
                    <b>Responding to your inquiries:</b>&nbsp;
                    When you contact us with a comment, question or complaint,
                    you may be asked for information that identifies you, such
                    as your name, address and telephone number, along with
                    additional information we need to help us promptly answer
                    your question or respond to your comment. We may retain this
                    information to assist you in the future and to improve our
                    customer service and service offerings (including the
                    Service and Website).
                  </p>
                  <p>
                    <b>Informing you about products and services.</b>&nbsp;
                    We may use your contact information for our own marketing or
                    advertising purposes. We do not sell or rent your Personal
                    Data to third parties. You can opt out of these at any time
                    by following the steps outlined below.
                    <ul>
                      <li>Opt out of our emailing list</li>
                      <li>
                        Send us a communication to request the deletion of your
                        contact details
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <h3>Recruiting and managing personnel</h3>
                  <p>
                    <b>Processing your job application.</b>&nbsp;
                    If you apply for a job at Wepos, you may provide us with
                    certain Personal Data about yourself, such as information
                    contained in a resume, cover letter, or similar
                    employment-related materials. We use this information for
                    the purpose of processing and responding to your application
                    for current and future career opportunities.
                  </p>
                  <p>
                    <b>Managing employees and contractors.</b>&nbsp;
                    If you join the Wepos team as an employee or contractor, we
                    will use the information you provided, as well as
                    information we create about you, for human resources
                    purposes, including verifying your eligibility and
                    qualifications, performance management, to provide
                    compensation and benefits, investigate incidents, and
                    otherwise facilitate the relationship.
                  </p>
                  <p>
                    <b>Collecting payment for the Service</b>&nbsp;
                    For Clients that purchase a paid version of our Service, we
                    collect and process information about how you use the
                    Service, and your Account Information (including contact
                    information, user profile information, and information about
                    your payment method) for the purpose of billing you.
                  </p>
                </div>
                <div>
                  <h2>To Understand and Improve our Services and Website</h2>

                  <p>
                    <b>Understanding how you use the Service.</b>&nbsp;
                    Service Usage Information is collected, including
                    information about how you are accessing and using the
                    Service. We use this information to understand and improve
                    our Services and to investigate and prevent security issues,
                    abuse, and fraud.
                  </p>
                  <p>
                    <b>Visiting our Website.</b>&nbsp;
                    We collect the IP (Internet protocol) addresses of all
                    visitors to our Website and other related information such
                    as page requests, browser type, operating system and average
                    time spent on our Website. We use this information to help
                    us understand our Website activity and to monitor and
                    improve our Website. In addition to the information
                    described above, our Website uses Cookies. Please refer to
                    the Cookie Policy below for additional details.
                  </p>
                  <p>
                    <b>Third-Party Links.</b>&nbsp;
                    Our Website may contain links to other websites that Wepos
                    does not own or operate. We provide links to third-party
                    websites as a convenience to the user. These links are not
                    intended as an endorsement of or referral to the linked
                    websites. The linked websites have separate and independent
                    privacy policies, notices and terms of use. We do not have
                    any control over such websites. Therefore we have no
                    responsibility or liability for the manner in which the
                    organizations that operate such linked websites may collect,
                    use or disclose, secure and otherwise treat Personal Data.
                    We encourage you to read the privacy policy of every website
                    you visit.
                  </p>
                </div>
                <div>
                  <h3>Disclosure of your Personal Data</h3>
                  <p>
                    As a matter of practice, Wepos does not disclose, trade,
                    rent, sell or otherwise transfer Personal Data, except as
                    set out in this policy. We may transfer or disclose Personal
                    Data as follows:
                  </p>
                  <p>
                    <div>
                      Service Provider Arrangements. We may transfer (or otherwise
                      make available) Personal Data to third parties who process
                      it on our behalf for the purposes noted above. These third
                      parties may access, process or store personal data in the
                      course of providing these services, but based on our
                      instructions only.
                    </div>
                    <div>
                      – As of the date hereof, these third-party providers include
                      technical operations such as database monitoring, data
                      storage and hosting services and customer support software
                      tools.
                    </div>
                  </p>
                  <p>
                    Changes to our Business Structure. Wepos may share or
                    disclose data if we engage in a merger, acquisition,
                    bankruptcy, dissolution, reorganization, sale of some or all
                    of Wepos’s assets, financing, acquisition of all or a
                    portion of our business, a similar transaction or
                    proceeding, or steps in contemplation of such activities
                    (e.g. due diligence).
                  </p>
                  <p>
                    Compliance with Laws. Wepos and our Partner Service
                    Providers of other countries may share or disclose Personal
                    Data to comply with legal or regulatory requirements and to
                    respond to lawful requests, court orders and legal
                    processes.
                  </p>
                  <p>
                    Enforcing Our Rights, Preventing Fraud, and Safety. Wepos
                    may share or disclose data to protect and defend the rights,
                    property, or safety of third parties or us, including
                    enforcing contracts or policies, or in connection with the
                    investigation and preventing fraud.
                  </p>
                </div>
                <div>
                  <h3>Your Rights</h3>
                  <h3>Access and Correction of Personal Data</h3>
                  <p>
                    <div>
                      If we receive a request from an individual to access or
                      update Personal Data we have collected on behalf of a
                      particular Customer, we will direct that individual to the
                      relevant Customer. We will assist our Customers wherever
                      possible in responding to individual access requests.
                    </div>
                    <div>
                      If you submit Personal Data via our Website or otherwise
                      provide us with your Personal Data, you may request access,
                      updating or correction of your Personal Data by submitting a
                      written request to us. We may request certain Personal Data
                      for the purposes of verifying your identity.
                    </div>
                  </p>
                </div>
                <div>
                  <h3>How We Protect Personal Data</h3>
                  <p>
                    Wepos takes the security of user data seriously. We take
                    various steps to protect the information you provide to us
                    from loss, misuse, and unauthorized access or disclosure.
                    These steps take into account the sensitivity of the
                    information we collect, process and store, and the current
                    state of technology.
                  </p>
                  <p>
                    To learn more about current practices and policies regarding
                    the security and confidentiality of Personal Data and other
                    information, please see our Security Practices; we keep that
                    document updated as these practices evolve over time.
                  </p>
                </div>
                <div>
                  <h3>Cookie Policy</h3>
                  <p>
                    Wepos uses cookies and similar technologies, to record log
                    data. We use both session-based and persistent cookies
                  </p>
                  <p>
                    Cookies are small text files sent by us to your computer and
                    from your computer or mobile device to us each time you
                    visit our website or use our desktop application. They are
                    unique to your account or your browser. Session-based
                    cookies last only while your browser is open and are
                    automatically deleted when you close your browser.
                    Persistent cookies last until you or your browser delete
                    them or until they expire
                  </p>
                  <p>
                    Some cookies are associated with your account and Personal
                    Data in order to remember that you are logged in and which
                    parts of the Service or Website you are logged into. Other
                    cookies are not tied to your account but are unique and
                    allow us to carry out site analytics and customization,
                    among other similar things. If you access the Services
                    through your browser, you can manage your cookie settings
                    there, but if you disable some or all cookies, you may not
                    be able to use the Services.
                  </p>
                  <p>
                    Wepos sets and accesses our own cookies on the domains
                    operated by Wepos and its affiliates. In addition, we use
                    third parties like Google Analytics for website analytics.
                    You may opt out of third-party cookies from Google Analytics
                    on its website.
                  </p>
                  <p>
                    We do not currently recognize or respond to
                    browser-initiated Do Not Track signals, as there is no
                    consistent industry standard for compliance.
                  </p>
                </div>
                <div>
                  <h3>Updates to this Privacy Policy</h3>
                  <p>
                    This Privacy Policy may be updated periodically to reflect
                    changes to our Personal Data handling practices. The revised
                    Privacy Policy will be posted on the Website. If you
                    continue to use the Service or the Website after the changes
                    are in effect, you agree to the revised Notice.
                  </p>
                  <p>
                    We strongly encourage you to please refer to this Privacy
                    Policy often for the latest information about our Personal
                    Data handling practices.
                  </p>
                </div>
                <div>
                  <h3>Contact Us</h3>
                  <p>Please contact Wepos if:</p>
                  <ul>
                    <li>
                      you have any questions or comments about this Privacy
                      Policy;
                    </li>
                    <li>
                      you wish to access, update, and/or correct inaccuracies in
                      your Personal Data; or
                    </li>
                    <li>
                      you otherwise have a question or complaint about the
                      manner in which our service providers or we treat your
                      Personal Data
                    </li>
                  </ul>
                  <p>
                    You can reach the Wepos Compliance Officer by emailing compliance@Wepos.ai or
                    at our mailing address below:
                  </p>
                </div>
                <div>
                  <h3>RAG TECH LTD</h3>
                  <h3>Flat 1010 3 Merchant Square, London W2 1BF</h3>
                </div>
              </section>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

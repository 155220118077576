import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment';
import { Badge, Card, CardHeader, Media, Table, Row, Button } from "reactstrap";
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { getFeedbacks } from '../../utils/middleware/feedbacks'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions } from '../../../../constants/General';
import { handleSortColumn, isAllowFeature, sortIcon } from '../../../../services/middleware';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import DateRangeSelector from '../../components/DateRangeSelector';

export default function Feedbacks() {
  // Store
  const { feedbacks, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminFeedbacks)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [dateRangeSelector, setDateRangeSelector] = useState(false)

  // Mount
  useEffect(() => {
    getFeedbacks(search, startDate, endDate, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminFeedbacks.SetPage, pageCount)
    }
  }, [search, startDate, endDate, activeSort, sortOrder, totalCount, page, pageSize])

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminFeedbacks.Reset), [])

  return (
    <>
      <div>
        <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 space-between-div table-header-div">
                  <h3 className="mb-0">Feedbacks</h3>
                  <div className="right-div-wrap">
                    <SearchInput action={RoutesActions.feedbacks} />
                    <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
                      {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                    </Button>
                  </div>
                </CardHeader>
                {feedbacks?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'feedbacks')}>No.</th> */}
                      <th scope='col' className={`${activeSort === 'customerNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('customerNumber', activeSort, sortOrder, 'feedbacks')}>Customer <FontAwesomeIcon icon={sortIcon(activeSort, 'customerNumber', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'createdAt' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('createdAt', activeSort, sortOrder, 'feedbacks')}>Date <FontAwesomeIcon icon={sortIcon(activeSort, 'createdAt', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'rating' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('rating', activeSort, sortOrder, 'feedbacks')}>Rating <FontAwesomeIcon icon={sortIcon(activeSort, 'rating', sortOrder)} /></th>
                      <th scope='col' className={`${activeSort === 'message' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('message', activeSort, sortOrder, 'feedbacks')}>Message <FontAwesomeIcon icon={sortIcon(activeSort, 'message', sortOrder)} /></th>
                      {isAllowFeature('orders') && <>
                        <th scope='col'>Total</th>
                        <th scope='col'>Order ID</th>
                        <th scope='col'>Table Number</th>
                      </>}
                    </tr>
                  </thead>
                  <tbody>
                    {feedbacks.map((feedback, index) => {
                      return <tr>
                        {/* <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th> */}
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {feedback?.customerNumber} {feedback?.customerName && `(${feedback?.customerName})`}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <th>{feedback?.createdAt ? moment(feedback.createdAt).format('DD-MM-YYYY') : ''}</th>
                        <th>{Array.from({ length: 5 }, (_, i) => i + 1).map((rate) => {
                          return (
                            <label key={rate}>
                              <FontAwesomeIcon
                                icon={rate <= feedback?.rating ? solidStar : regularStar}
                                className="star"
                                color="#ffc107"
                                size='xl'
                              />
                            </label>
                          );
                        })}</th>
                        <th>{feedback?.message}</th>
                        {isAllowFeature('orders') && <>
                          <td>{feedback?.total &&
                            <Badge className="badge-warning" style={{ fontSize: 12 }}>
                              {`${settings?.superAdminSetting?.currencySign}${feedback.total}`}
                            </Badge>}</td>
                          <th>{feedback?.orderId}</th>
                          <th>{feedback?.tableNumber}</th>
                        </>}
                      </tr>
                    })}
                  </tbody>
                </Table> : <NoDataLabel />}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminFeedbacks.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>

        {dateRangeSelector && <DateRangeSelector
          open={dateRangeSelector}
          handleCloseModal={() => setDateRangeSelector(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => dispatch(Actions.RestaurantAdminFeedbacks.SetStartDate, date)}
          setEndDate={(date) => dispatch(Actions.RestaurantAdminFeedbacks.SetEndDate, date)}
        />}
      </div>
    </>
  )
}

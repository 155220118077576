import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import UploadLogo from '../../../../components/UpdateLogo';

export default function ViewProductModal(props) {
  // Props
  const { open, product, handleCloseModal } = props

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          {product?.name || 'View Product'}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <UploadLogo fieldName="imageLink" logo={product?.imageLink} editable={false} />
              <Row>
                <Col md={6}>
                  <InputField disabled placeholder="Title" value={product.name} />
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Price" value={product.price} />
                </Col>
              </Row>
              {product?.saleStart && product?.saleEnd && <Row>
                <Col md={6}>
                  <InputField disabled placeholder="Sale Start Date" value={product.saleStart ? moment(product.saleStart).format('DD-MM-YYYY hh:mm A') : ''} />
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Sale End Date" value={product.saleStart ? moment(product.saleEnd).format('DD-MM-YYYY hh:mm A') : ''} />
                </Col>
              </Row>}
              <Row>
                <Col md={6}>
                  <InputField disabled placeholder="Discount Type" value={product.discountType} />
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Discount" value={product.discountValue} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputField disabled placeholder="Category" value={product.category} />
                </Col>
                <Col md={6}>
                  <Label for="exampleSelect" className='inputfield'>
                    Recommended
                  </Label>
                  <InputGroup className='recommended-field'>
                    <label className="custom-toggle">
                      <Input type="checkbox"
                        className="form-control"
                        name="recommended"
                        checked={product?.recommended}
                        disabled
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField disabled placeholder="Description" value={product.description} />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button color='primary' type="button" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    </Modal >
  )
}

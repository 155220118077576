import React from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { campaignValidationSchema } from '../../../../constants/Schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import { handleError } from '../../../../utils/toast';
import { CAMPAIGN_FILE_TYPE, ImageLinks, ImageType, selectDropdownStyle } from '../../../../constants/General';
import { useSelector } from 'react-redux';

export default function CampaignModal(props) {
  // Props
  const { open, campaign, handleCloseModal, handleSuccess } = props

  // Store
  const { tags, customers } = useSelector(state => state.restaurantAdminMarketings)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: campaign?.name || '',
      message: campaign?.message || '',
      allCustomers: campaign?.customers?.length === customers?.length,
      customers: !!campaign?.customers?.length ? campaign?.customers.map(customer => ({ label: customer?.name || '', value: customer?.id || '' })) : [],
      tags: !!campaign?.tags?.length ? campaign?.tags.map(tag => ({ label: tag?.tagName || '', value: tag?.id || '' })) : [],
      media: campaign?.media || ''
    },
    validationSchema: campaignValidationSchema,
    onSubmit: async (values) => {
      trimObjectValues(values, 'media')
      const filterData = removeEmptyKeysFromObject(values)
      if (filterData.media && !(typeof filterData.media === 'string' && ImageLinks.includes(filterData.media?.split(":")[0]))) {
        dispatch(Actions.RestaurantAdmin.SetLoading, true)
        const fileType = filterData?.media?.type?.split('/')[1]
        const logoResponse = await uploadImage(filterData.media, fileType === 'pdf' ? ImageType.CAMPAIGN_PDF : ImageType.CAMPAIGN_IMAGE).catch(err => {
          dispatch(Actions.RestaurantAdmin.SetLoading, false)
          handleError(err)
        })
        filterData.media = logoResponse.fileLink;
        filterData.mediaType = fileType === 'pdf' ? 'pdf' : 'image';
      } else if (filterData.media && typeof filterData.media === 'string' && ImageLinks.includes(filterData.media?.split(":")[0])) {
        const filename = filterData.media.substring(filterData.media.lastIndexOf('/') + 1); // Get filename from URL
        const extension = filename.split('.').pop().toLowerCase(); // Get extension
        filterData.mediaType = extension === 'pdf' ? 'pdf' : 'image';
      }
      filterData.tags = filterData?.tags?.length ? filterData.tags.map(tag => tag.value) : []
      if (!!formik.values.allCustomers) {
        filterData.customers = customers
        delete filterData.allCustomers
      }
      filterData.customers = filterData?.customers?.length ? filterData.customers.map(customer => customer.value) : []
      handleSuccess(filterData)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Campaign
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField placeholder="Media" fieldName="media" inputType="file" accept={CAMPAIGN_FILE_TYPE} formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for="exampleSelect" className='inputfield'>
                    Tags
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isMulti className="custom-select" options={tags} value={formik.values?.tags} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("tags", selectedOption);
                      }}
                    />
                    {formik.errors.tags && <span className="error-message input-group-alternative">{formik.errors.tags}</span>}
                  </section>
                </Col>
                <Col md={2} className='flex-center all-customers-checkbox'>
                  <FormGroup check inline className="custom-checkbox">
                    <Input type="checkbox" name='allCustomers' checked={formik.values.allCustomers} onChange={formik.handleChange} />
                  </FormGroup>
                  <Label className="inputfield mb-0">All Customers</Label>
                </Col>
                <Col md={6}>
                  <Label className='inputfield'>
                    Customers
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select isMulti isDisabled={formik.values.allCustomers} value={formik.values.customers} styles={selectDropdownStyle}
                      onChange={selectedOption => formik.setFieldValue("customers", selectedOption)}
                      options={customers} className="custom-select" />
                    {!!formik.errors?.customers?.length && Array.isArray(formik.errors?.customers) ? <span className="error-message input-group-alternative">{formik.errors.customers.map((customer, index) => (`customer ${index + 1} ${customer?.value}`)).join(",")}</span>
                      : formik.errors.customers && <span className="error-message input-group-alternative">{formik.errors.customers}</span>}
                  </section>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField placeholder="Message" fieldName="message" inputType="textarea" rows={5} formik={formik} />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}
